import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CF from "../../utils/CommonFunction";
import axiosInstance from "../../helpers/axiosInstance";

const customStyles = {
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    marginTop: "17px",
    marginRight: "20px",
  },
};

export default function DeletePostModal({
  open,
  setOpen,
  loading,
  setLoading,
  setToast,
  postId,
  onCallbackOfDeleteFeedPost,
}) {
  const handleClickDeleteFeedPost = (postId) => {
    setLoading(true);
    axiosInstance
      .get("/post/remove?post_id=" + postId)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          onCallbackOfDeleteFeedPost();
          setOpen(false);
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error("Something went wrong, while deleting feed post.")
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this feed post?
        </DialogTitle>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button style={customStyles.submitBtn} onClick={() => setOpen(false)}>
            No
          </Button>
          <Button
            onClick={(e) => handleClickDeleteFeedPost(postId)}
            style={customStyles.submitBtn}
            disabled={loading}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
