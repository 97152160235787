import React from "react";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import classes from "./Controls.module.css";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import PersonAdd from '@material-ui/icons/PersonAddOutlined';

const Controls = ({ activeControl, onChangeActiveTabs }) => {
  return (
    <div className={classes.container}>
      <div
        className={`${classes.control} ${
          activeControl === "lectures" && classes.active
        }`}
        onClick={() => onChangeActiveTabs("lectures")}
      >
        <FavoriteBorderIcon fontSize="large" style={{ color: "white" }} />
        <label>View Favorites</label>
      </div>
      <div
        className={`${classes.control} ${
          activeControl === "playlist" && classes.active
        }`}
        onClick={() => onChangeActiveTabs("playlist")}
      >
        <PlaylistPlayIcon fontSize="large" style={{ color: "white" }} />
        <label>Playlist</label>
      </div>
      <div
        className={`${classes.control} ${
          activeControl === "favorite-teachers" && classes.active
        }`}
        onClick={() => onChangeActiveTabs("favorite-teachers")}
      >
        <PersonAdd fontSize="large" style={{ color: "white" }} />

        <label>Follow Teachers</label>
      </div>
    </div>
  );
};

export default Controls;
