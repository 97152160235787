import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../helpers/axiosInstance";
import { UIContext } from "../contexts/UIContext";
import { useParams, useNavigate } from "react-router-dom";
import Search from "../components/search/Search";

/**
 * @author Nikita Mahajan
 * Date: 19 september 2022
 * @param {string} title - Search feed with onclick of feed show details of feed.
 */

function SearchContainer() {
  const { setLoading } = useContext(UIContext);
  const [searchData, setSearchData] = useState(null);
  const { queryString } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  /* Get fetch search data */
  useEffect(() => {
    getSearchData(queryString);
  }, [queryString]);

  /* API Call Axios */
  const getSearchData = (queryString) => {
    setIsLoading(true);
    axiosInstance
      .get(`algolia/search-feed?q=${queryString}`)
      .then((res) => {
        setSearchData(res.data.feed);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Search
        navigate={navigate}
        isLoading={isLoading}
        searchData={searchData}
        setLoading={setLoading}
      />
    </>
  );
}

export default SearchContainer;
