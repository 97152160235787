import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Rating } from "@mui/material";
import CF from "../../utils/CommonFunction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

const customStyles = {
  imageWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  profileImage: {
    height: "3rem",
    width: "3rem",
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: "1rem",
  },
  rating: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  overall: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  ratingStars: {
    marginLeft: "1rem",
    marginRight: "2rem",
  },
};

const ViewRatingsModal = ({ open, setOpen, feedDetail }) => {
  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ backgroundColor: "#1784E9", border: "none" }}>
          <div style={customStyles.imageWrapper}>
            <img
              referrerpolicy="no-referrer"
              src={feedDetail?.teacher?.profileImage}
              style={customStyles.profileImage}
              alt="profile image"
            />
            <h3>
              {feedDetail?.teacher?.teacher_title
                ? feedDetail?.teacher?.teacher_title
                : "Prof"}
              . {CF.capitalizeFirstLetter(feedDetail?.teacher?.firstName)}
              &nbsp;
            </h3>
          </div>
          <div style={customStyles.overall}>
            <h2>Overall Rating</h2>
            <h3 style={{ marginBottom: "1rem" }}>
              {feedDetail?.teacher_post_rating?.toFixed(1)}
            </h3>
            <Rating
              value={feedDetail?.teacher_post_rating}
              precision={0.5}
              readOnly
              style={{ marginBottom: "2rem" }}
            />
            <div>
              <div styles={customStyles.rating}>
                <>
                  5
                  <Rating
                    style={customStyles.ratingStars}
                    value={
                      feedDetail?.teacher?.average_post_rating?.five_rating
                        ?.five_rating_count
                    }
                    readOnly
                  />
                </>
                <label>
                  {
                    feedDetail?.teacher?.average_post_rating?.five_rating
                      ?.five_rating_users
                  }
                </label>
              </div>
              <div styles={customStyles.rating}>
                <>
                  4
                  <Rating
                    style={customStyles.ratingStars}
                    value={
                      feedDetail?.teacher?.average_post_rating?.four_rating
                        ?.four_rating_count
                    }
                    readOnly
                  />
                </>
                <label>
                  {
                    feedDetail?.teacher?.average_post_rating?.four_rating
                      ?.four_rating_users
                  }
                </label>
              </div>
              <div styles={customStyles.rating}>
                <>
                  3
                  <Rating
                    style={customStyles.ratingStars}
                    value={
                      feedDetail?.teacher?.average_post_rating?.three_rating
                        ?.three_rating_count
                    }
                    readOnly
                  />
                </>
                <label>
                  {
                    feedDetail?.teacher?.average_post_rating?.three_rating
                      ?.three_rating_users
                  }
                </label>
              </div>
              <div styles={customStyles.rating}>
                <>
                  2
                  <Rating
                    style={customStyles.ratingStars}
                    value={
                      feedDetail?.teacher?.average_post_rating?.two_rating
                        ?.two_rating_count
                    }
                    readOnly
                  />
                </>
                <label>
                  {
                    feedDetail?.teacher?.average_post_rating?.two_rating
                      ?.two_rating_users
                  }
                </label>
              </div>
              <div styles={customStyles.rating}>
                <>
                  1
                  <Rating
                    style={customStyles.ratingStars}
                    value={
                      feedDetail?.teacher?.average_post_rating?.one_rating
                        ?.one_rating_count
                    }
                    readOnly
                  />
                </>
                <label>
                  {
                    feedDetail?.teacher?.average_post_rating?.one_rating
                      ?.one_rating_users
                  }
                </label>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ViewRatingsModal;
