import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  DialogActions,
} from "@material-ui/core";
import Toast from "../../components/ui/toast/Toast";
import CF from "../../utils/CommonFunction";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "inherit",
    height: "auto",
    width: "100%",
  },
  ogRoot: {
    "& .MuiDialog-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiDialog-paperWidthSm": {
      width: 300,
      maxWidth: "unset",
      height: "220px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paper": {
        margin: 0,
      },
      "& .MuiDialog-paperWidthSm": {
        width: 700,
        height: "90%",
        maxWidth: "unset",
      },
    },
  },
  playLiStyle: {
    borderRadius: "5px",
    marginBottom: "0.5rem",
    cursor: "pointer",
    padding: " 5px",
    textAlign: "center",
  },
  viewmore: {
    textAlign: "center",
  },
}));

export default function PostPlayListingModal({
  playlistModalOpen,
  setOpen,
  playListDetails,
  postId,
  handleSubmit,
  submitFunction,
  feedFormData,
}) {
  const classes = useStyles();
  const [toast, setToast] = useState();
  const [selectPlaylistValue, setSelectPlaylistValue] = useState("");

  const handleChange = (event) => {
    setSelectPlaylistValue(event.target.value);
  };

  const onClickOfPlayList = (e, playListId) => {
    if (!selectPlaylistValue) {
      setToast(CF.alert.error("Please Select Play List"));
    } else {
      if (typeof submitFunction === "undefined") {
        handleSubmit({
          ...feedFormData,
          playlist_id: playListId,
        });
      } else {
        submitFunction(e, "playlist", postId, playListId);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={playlistModalOpen}
        onClose={() => setOpen(false)}
        style={{
          zIndex: 1300,
        }}
        scroll={"body"}
        classes={{
          paperScrollPaper: classes.root,
          root: classes.ogRoot,
        }}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3
              style={{
                textAlign: "center",
              }}
            >
              Playlist
            </h3>
            <CloseIcon
              onClick={() => setOpen(false)}
              fontSize="large"
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl fullWidth>
            <InputLabel
              id="select-playlist-label"
              style={{ marginLeft: "10px", marginTop: "-5px" }}
            >
              Select Playlist
            </InputLabel>
            <Select
              labelId="select-playlist-label"
              id="select-playlist"
              label="Select Playlist"
              variant="outlined"
              size="medium"
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={selectPlaylistValue}
              onChange={handleChange}
            >
              {playListDetails.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#87CA87",
              width: "90%",
              margin: "0 auto",
            }}
            onClick={(e) => onClickOfPlayList(e, selectPlaylistValue)}
          >
            Add to playlist
          </Button>
        </DialogActions>
      </Dialog>
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
}
