import React, { useContext } from "react";
import CF from "../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import NavbarAdmin from "../components/navbar-admin/NavbarAdmin";

export default function NavbarAdminContainer() {
  const { identity, setIdentity } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    //Get current url
    const currentPageUrl = window.location.pathname;
    if (identity.userType == "super") {
      CF.lastPageAccess.set({
        super: {
          url: currentPageUrl,
        },
      });
    } else {
      CF.lastPageAccess.set({
        admin: {
          url: currentPageUrl,
        },
      });
    }
    setIdentity({
      isLoggedIn: false,
      username: "",
      userType: "",
    });
    localStorage.removeItem("userIdentity");
    navigate("/");
  };

  return (
    <div>
      <NavbarAdmin logout={logout} identity={identity} />
    </div>
  );
}
