import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { UIContext } from "../../../contexts/UIContext";
import classes from "./loginNew.module.css";
import Toast from "../../../components/ui/toast/Toast";
import CF from "../../../utils/CommonFunction";
import LoginModal from "../../Dialogs/LoginModal";
import useViewport from "../../../contexts/useViewPort";
import clsx from "clsx";
import { GoogleLogin } from "@react-oauth/google";
import multiColorIcon from "../../../assets/images/multiColourIcon.png";
import learnWayIcon from "../../../assets/images/larnWayIcon.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [toast, setToast] = useState();
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const { setIdentity } = useContext(AuthContext);
  const { setLoading } = useContext(UIContext);
  let navigate = useNavigate();
  const { width } = useViewport();

  const saveUser = (user) => {
    setIdentity({
      isLoggedIn: true,
      userType: user.userType,
      userId: user.userId,
    });
    localStorage.setItem(
      "userIdentity",
      JSON.stringify({
        userType: user.userType,
        accessToken: user.accessToken,
        userId: user.userId,
      })
    );
    if (user.userType === "super") {
      navigate("/super-admin-dashboard");
    } else if (user.userType === "admin") {
      navigate("/admin-dashboard");
    } else {
      if (user.newUser) {
        if (user.userType === "student") {
          navigate("/student-profile" + "/lectures");
          return false;
        } else if (user.userType === "teacher") {
          navigate("/teacher-profile/" + user.userId + "/lectures");
          return false;
        }
      }
      navigate("/dashboard");
    }
  };

  const handleCallbackResponse = (response) => {
    setLoading(true);
    axiosInstance
      .post("/auth/googleSignIn", { idToken: response.credential })
      .then((res) => {
        if (res.data.status === false) {
          setToast(CF.alert.error(res.data.msg));
        } else {
          saveUser(res.data.user);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response && err.response.data) {
          setToast(CF.alert.error(err.response.data.msg));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      alert("Username and password are required");
      return;
    }
    setLoading(true);
    axiosInstance
      .post("/auth/login", { username, password })
      .then((res) => {
        saveUser(res.data.user);
        setLoading(false);
        setOpenLoginForm(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response && err.response.data) {
          setToast({
            status: "error",
            title: "Login Error",
            body: err.response.data.msg,
          });
        }
      });
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Box mx={8} className={classes.loginText}>
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="h2"
              style={{ fontWeight: 700, color: "#353535" }}
            >
              LOGIN
            </Typography>
          </Box>
          <Box className={classes.infoText2}>
            <Typography>
              Click below to sign in with your school provided Gmail account.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              maxWidth: width < 821 ? "470px" : width < 930 ? "570px" : "650px",
            }}
          >
            <Box
              ml={width < 930 ? 5 : 8}
              mb={-2.5}
              sx={{ position: "relative", zIndex: 2 }}
            >
              <Box className={classes.googleSSO}>
                <GoogleLogin
                  onSuccess={handleCallbackResponse}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  width="300px"
                />
              </Box>
              <Box
                className={classes.loginDetails}
                onClick={() => setOpenLoginForm(true)}
              >
                <Typography
                  onClick={() => setOpenLoginForm(true)}
                  variant="body2"
                >
                  *Log-in with username/password
                </Typography>
              </Box>
            </Box>
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <img
                style={{ width: width < 930 ? "85%" : "100%" }}
                src={multiColorIcon}
                className={classes.leftCornerImg}
              />
            </Box>
          </Box>
          <Box className={classes.learnWayImg}>
            <img src={learnWayIcon} />
          </Box>
        </Box>
      </Grid>
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
      <LoginModal
        open={openLoginForm}
        setOpen={setOpenLoginForm}
        setUsername={setUsername}
        setPassword={setPassword}
        handleSubmit={handleSubmit}
        username={username}
        password={password}
      />
    </>
  );
};
export default Login;
