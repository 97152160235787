import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import NavbarAdminContainer from "../containers/NavbarAdminContainer";

// Pages
import SuperAdminDashboard from "../pages/super-admin/dashboard/Dashboard";
import AdminDashboard from "../pages/admin/dashboard/Dashboard";
import PageNotFound from "../pages/page-not-found/PageNotFound";

const SuperAdminRoutes = () => {
  return (
    <>
      <NavbarAdminContainer />
      <Routes>
        <Route exact path="/" element={<SuperAdminDashboard />} />
        <Route
          exact
          path="/super-admin-dashboard"
          element={<SuperAdminDashboard />}
        />
        <Route
          exact
          path="/institution/:institutionId"
          element={<AdminDashboard />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default SuperAdminRoutes;
