import React from "react";
import { Rating } from "@mui/material";
import classes from "./ViewRatings.module.css";

const ViewRatings = ({ ratingInfo }) => {
  return (
    <div className={classes.container}>
      <h1>Ratings and Reviews</h1>
      <div className={classes.wrapper}>
        <div className={classes.overall}>
          <h2>Overall Rating</h2>
          <h3>{ratingInfo?.overall_post_rating?.toFixed(1)}</h3>
          <Rating
            defaultValue={ratingInfo?.overall_post_rating}
            precision={0.5}
            className={classes.ratingStar}
            readOnly
          />
        </div>
        <div>
          <div className={classes.rating}>
            <>
              5
              <Rating
                value={
                  ratingInfo?.average_post_rating?.five_rating
                    ?.five_rating_count
                }
                readOnly
              />
            </>
            <label>
              {ratingInfo?.average_post_rating?.five_rating?.five_rating_users}
            </label>
          </div>
          <div className={classes.rating}>
            <>
              4
              <Rating
                value={
                  ratingInfo?.average_post_rating?.four_rating
                    ?.four_rating_count
                }
                readOnly
              />
            </>
            <label>
              {ratingInfo?.average_post_rating?.four_rating?.four_rating_users}
            </label>
          </div>
          <div className={classes.rating}>
            <>
              3
              <Rating
                value={
                  ratingInfo?.average_post_rating?.three_rating
                    ?.three_rating_count
                }
                readOnly
              />
            </>
            <label>
              {
                ratingInfo?.average_post_rating?.three_rating
                  ?.three_rating_users
              }
            </label>
          </div>
          <div className={classes.rating}>
            <>
              2
              <Rating
                value={
                  ratingInfo?.average_post_rating?.two_rating?.two_rating_count
                }
                readOnly
              />
            </>
            <label>
              {ratingInfo?.average_post_rating?.two_rating?.two_rating_users}
            </label>
          </div>
          <div className={classes.rating}>
            <>
              1
              <Rating
                value={
                  ratingInfo?.average_post_rating?.one_rating?.one_rating_count
                }
                readOnly
              />
            </>
            <label>
              {ratingInfo?.average_post_rating?.one_rating?.one_rating_users}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRatings;
