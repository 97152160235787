import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const customStyles = {
  inputDiv: {
    marginBottom: "1rem",
  },
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
  },
  btnDiv: {
    textAlign: "center",
  },
};

export default function UpdateStudentDetailsModal({
  open,
  setOpen,
  teacherData,
  handleTeacherDataChange,
  handleSubmit,
  editTeacherInfo
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle id="alert-dialog-title">
            Update Student Details
          </DialogTitle>
          <CloseIcon
            onClick={() => setOpen(false)}
            fontSize="medium"
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <DialogContent>
          <form>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                label="First Name"
                name="firstName"
                type="text"
                value={teacherData.firstName}
                onChange={handleTeacherDataChange}
                required
              />
            </div>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                label="Last Name"
                name="lastName"
                value={teacherData.lastName}
                onChange={handleTeacherDataChange}
                required
              />
            </div>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                label="About me"
                name="bio"
                multiline
                rows={4}
                value={teacherData.bio}
                onChange={handleTeacherDataChange}
              />
            </div>
            <div style={customStyles.btnDiv}>
              <Button
                variant="contained"
                style={customStyles.submitBtn}
                onClick={(e) => handleSubmit(e, editTeacherInfo.userId)}
              >
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
