import React, { useState, useEffect, useContext } from "react";
import BackArrowIcon from "@material-ui/icons/ArrowBack";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, Switch } from "@material-ui/core";
import CF from "../../../utils/CommonFunction";
import classes from "./TeacherDetails.module.css";
import { UIContext } from "../../../contexts/UIContext";
import axiosInstance from "../../../helpers/axiosInstance";
import Avatar from "@material-ui/core/Avatar";
import EraseChannelModal from "../../Dialogs/EraseChannelModal";
import moment from "moment";

const TeacherDetails = ({
  setSelectedTeacherId,
  selectedTeacherId,
  setToast,
  onCallbackOfEraseChannel,
}) => {
  const { loading, setLoading } = useContext(UIContext);
  const [actionMute, setActionMute] = React.useState(0);
  const [actionRestrict, setActionRestrict] = React.useState(0);
  const [eraseChannelModalOpen, setEraseChannelModalOpen] = useState(false);

  const [teacher, setTeacher] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    bio: "",
    comments: [],
  });

  useEffect(() => {
    if (selectedTeacherId > 0) {
      getTeacherDetails(selectedTeacherId);
    }
  }, [selectedTeacherId]);

  // const onSearchEvent = (e) => {
  //   getTeacherDetails(selectedTeacherId, e.target.value);
  // };

  const getTeacherDetails = (teacherId, searchText) => {
    searchText = searchText || "";
    setLoading(true);
    axiosInstance
      .get(`/teacher/details?teacher_id=${teacherId}&q=${searchText}`)
      .then((res) => {
        setTeacher(res.data.teacher);
        setActionMute(res.data.teacher.is_mute);
        setActionRestrict(res.data.teacher.is_restrict_posting);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickDeleteAllPost = (teacherId) => {
    setLoading(true);
    axiosInstance
      .get("/post/delete?teacher_id=" + teacherId)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          getTeacherDetails(selectedTeacherId);
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(CF.alert.error("Something went wrong, while deleting post."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickEraseChannel = (teacherId) => {
    setLoading(true);
    axiosInstance
      .get("/admin/erase-channel?teacher_id=" + teacherId)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          onCallbackOfEraseChannel();
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error("Something went wrong, while erase channel post.")
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeMuteRestrictAction = (e, teacherId, actionType) => {
    let requestData = {};
    let currentlyIsChecked;
    let finallyIsChecked = e.target.checked ? "1" : "0";
    if (actionType == "mute") {
      currentlyIsChecked = actionMute;
      requestData = {
        is_mute: finallyIsChecked,
      };
      setActionMute(finallyIsChecked);
    } else {
      currentlyIsChecked = actionRestrict;
      requestData = {
        is_restrict_posting: finallyIsChecked,
      };
      setActionRestrict(finallyIsChecked);
    }
    setLoading(true);
    axiosInstance
      .put("/teacher/quick-action?teacher_id=" + teacherId, requestData)
      .then((res) => {
        if (res.data.state === true) {
          // setToast(CF.alert.success(res.data.msg));
        } else {
          if (actionType == "mute") {
            setActionMute(currentlyIsChecked);
          } else {
            setActionRestrict(currentlyIsChecked);
          }
          //  setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        console.log(err);
        if (actionType == "mute") {
          setActionMute(currentlyIsChecked);
        } else {
          setActionRestrict(currentlyIsChecked);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickDeleteComment = (e, commentId) => {
    setLoading(true);
    axiosInstance
      .get("/post/remove-comments?comment_id=" + commentId)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          getTeacherDetails(selectedTeacherId);
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error("Something went wrong, while delete institution.")
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div>
          <IconButton
            aria-label="back button"
            onClick={() => setSelectedTeacherId(null)}
          >
            <BackArrowIcon />
          </IconButton>
          <label>Teacher Details</label>
        </div>
        <div className={classes.details}>
          <div className={classes.teacherProfile}>
            <Avatar
              src={teacher?.profileImage}
              loading="lazy"
              style={{
                height: "7rem",
                width: "7rem",
                marginTop: "-10px",
              }}
              className={classes.teacherImg}
            >
              {CF.capitalizeFirstLetter(teacher?.firstName.substring(0, 1))}
            </Avatar>
            <div>
              <h3 className={classes.nameText}>
                {teacher.teacher_title ? teacher.teacher_title : "Prof"}.{" "}
                {CF.capitalizeFirstLetter(teacher?.firstName)} &nbsp;
                {teacher?.lastName}
              </h3>
              <p> {teacher?.institution?.name}</p>
              {teacher?.subject && <p>Teaches &nbsp;{teacher?.subject}</p>}
            </div>
          </div>
          <div className={classes.stats}>
            <div className={classes.stat}>
              <label className={classes.statValue}>{teacher?.post_count}</label>
              <label className={classes.statLabel}>Posts</label>
            </div>
            <div className={classes.stat}>
              <label className={classes.statValue}>{teacher?.post_views}</label>
              <label className={classes.statLabel}>Views</label>
            </div>
            {/* <div className={classes.stat}>
              <label className={classes.statValue}>
                {teacher?.overall_post_rating?.toFixed(1)}
              </label>
              <label className={classes.statLabel}>Rating</label>
            </div> */}
            <div className={classes.stat}>
              <label className={classes.statValue}>
                {moment(teacher?.createdAt).format("DD/MM/YYYY")}
              </label>
              <label className={classes.statLabel}>Joining Date</label>
            </div>
          </div>

          <h3>Quick Actions</h3>
          <div>
            <Switch
              checked={actionMute == "1"}
              onChange={(e) =>
                handleChangeMuteRestrictAction(e, teacher.userId, "mute")
              }
            />
            <label className={classes.muteBtn}>Mute</label>
            <Switch
              checked={actionRestrict == "1"}
              onChange={(e) =>
                handleChangeMuteRestrictAction(e, teacher.userId, "restrict")
              }
            />
            <label className={classes.muteBtn}>Restrict Posting</label>
          </div>
          <div className={classes.btnDiv}>
            <span style={{ cursor: "not-allowed" }}>
              <Button
                variant="outlined"
                startIcon={<DeleteOutlineIcon fontSize="small" />}
                style={{
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "1rem",
                }}
                className={classes.actionBtn}
                onClick={(e) => handleClickDeleteAllPost(teacher.userId)}
                disabled={loading || !teacher?.post_count}
              >
                Delete Posts
              </Button>
            </span>
            <Button
              variant="outlined"
              startIcon={<CloseIcon fontSize="small" />}
              className={classes.actionBtn}
              style={{
                color: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
                marginRight: "1rem",
              }}
              onClick={() => setEraseChannelModalOpen(true)}
            >
              Erase Channel
            </Button>
          </div>
        </div>
      </div>
      <EraseChannelModal
        open={eraseChannelModalOpen}
        setOpen={setEraseChannelModalOpen}
        loading={loading}
        handleClickEraseChannel={handleClickEraseChannel}
        teacher={teacher}
      />
    </>
  );
};

export default TeacherDetails;
