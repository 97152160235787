import React from "react";
import classes from "./Dashboard.module.css";

const SearchBar = ({ onSearchEvent, feedType }) => {
  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        type="text"
        placeholder="Search"
        onChange={(e) => onSearchEvent(e, feedType)}
      />
    </div>
  );
};

export default SearchBar;
