import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/AuthContext";
import axiosInstance from "../helpers/axiosInstance";
import { UIContext } from "../contexts/UIContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Lectures from "../components/lectures/Lectures";

const LecturesContainer = ({
  lectures = [],
  lectureItemClickHandler,
  onActionCallBack,
  feedType,
  onCallbackOfDeleteFeedPost,
  getFeed,
  blockWidth = "100%",
  isLoading,
  activeTabType,
}) => {
  const { setLoading } = useContext(UIContext);
  const { identity } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  /* Views count api with details page */
  const handleClick = (feedId, teacherId, slug) => {
    if (lectureItemClickHandler === undefined) return;
    let requestData = {
      post_id: feedId,
      post_user_id: teacherId,
      user_type: identity.userType,
    };

    if (location.pathname.includes("/dashboard")) {
      navigate(`/dashboard/${slug}`);
    } else if (
      location.pathname.includes(`/teacher-profile/${teacherId}/lectures`) ||
      activeTabType === "favorite"
    ) {
      navigate(`/teacher-profile/${teacherId}/lectures/${slug}`);
    } else if (location.pathname.includes(`/student-profile/lectures`)) {
      navigate(`/student-profile/lectures/${slug}`);
    } else if (
      location.pathname.includes(`/teacher-profile/${teacherId}/playlist`)
    ) {
      navigate(`/teacher-profile/${teacherId}/playlist/${slug}`);
    } else if (location.pathname.includes(`/student-profile/playlist`)) {
      navigate(`/student-profile/playlist/${slug}`);
    }

    axiosInstance
      .post("/post/view", requestData)
      .then((res) => {
        setLoading(false);
        lectureItemClickHandler(feedId);
        getFeed("main-feed");
        getFeed("following-feed");
        getFeed("favourite-feed");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (lectures.length > 0 && slug) {
      let tempData = lectures.filter((item) => item.slug === slug)[0];
      if (tempData?.id) {
        lectureItemClickHandler(tempData?.id);
      }
    }
  }, [lectures]);

  return (
    <>
      <Lectures
        getFeed={getFeed}
        lectures={lectures}
        handleClick={handleClick}
        identity={identity}
        navigate={navigate}
        onActionCallBack={onActionCallBack}
        feedType={feedType}
        onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
        isLoading={isLoading}
        blockWidth={blockWidth}
      />
    </>
  );
};

export default LecturesContainer;
