import React, { useState, useContext, useEffect } from "react";
import BackIcon from "@material-ui/icons/ArrowBack";
import LecturesContainer from "../../containers/LecturesContainer";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddPlayListModal from "../Dialogs/AddPlayListModal";
import axiosInstance from "../../helpers/axiosInstance";
import { UIContext } from "../../contexts/UIContext";
import Toast from "../../components/ui/toast/Toast";
import CF from "../../utils/CommonFunction";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import LectureDetailsContainer from "../../containers/LectureDetailsContainer";
import StudentPlayListFavuriteMenu from "../../pages/student-profile/StudentPlayListFavuriteMenu";
import classes from "./TeacherControls.module.css";

const customStyles = {
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    position: "relative",
    margin: "-53px -93px",
    top: "50%",
    left: "50%",
  },
};

const Playlist = ({
  selectedPlaylist,
  setSelectedPlaylist,
  teacherId,
  isPlayListSubmited,
  onCallbackOfDeleteFeedPost,
}) => {
  const { id } = useParams();
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const [playListDetails, setPlayListDetails] = useState([]);
  const { loading, setLoading } = useContext(UIContext);
  const [title, setTitle] = useState("");
  const [toast, setToast] = useState();
  const { identity } = useContext(AuthContext);
  const [selectedPlayListFeeds, setSelectedPlayListFeeds] = useState([]);
  const [selectedFeedId, setSelectedFeedId] = useState(0);
  const [selectedPlayListId, setSelectedPlayListId] = useState(0);

  useEffect(() => {
    if (isPlayListSubmited) {
      getPlayListDetails(id);
    } else {
      setTitle("");
    }
  }, [isPlayListSubmited]);

  const viewSelectedFeedDetail = (feedId) => {
    setSelectedFeedId(feedId);
  };

  useEffect(() => {
    setTitle("");
  }, [playlistModalOpen]);

  //Validation for create play list
  const validateCreatePlayList = () => {
    let message = [];
    if (title.trim() === "") {
      message.push("Please, enter title.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast(CF.alert.error(message[i]));
      }
    }
    return message.length === 0;
  };

  //Create PlayList
  const createPlayList = () => {
    const isValid = validateCreatePlayList();
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/playlist/save", { title })
        .then((res) => {
          if (res.data.state === true) {
            setToast(CF.alert.success(res.data.msg));
            setPlaylistModalOpen(false);
            getPlayListDetails(id);
          } else {
            setToast(CF.alert.error(res.data.msg));
          }
        })
        .catch((err) => {
          setToast(
            CF.alert.error("Something went wrong, while creating playlist.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getPlayListDetails(id);
  }, []);

  const manipulateDataForPost = (playlistData) => {
    for (let i = 0; i < playlistData.length; i++) {
      let _manipulatedFeed = [];
      if (playlistData[i].hasOwnProperty("post_saves")) {
        let singleFeed = playlistData[i]["post_saves"];
        for (let j = 0; j < singleFeed.length; j++) {
          let singlePost = singleFeed[j].post;
          _manipulatedFeed.push({
            ...singlePost,
            teacher: playlistData[i].teachers[0],
          });
        }
      }
      playlistData[i]["_manipulatedFeedData"] =
        CF.assignRandomColorCodeInArray(_manipulatedFeed);
    }
    return playlistData;
  };
  //Listing API For PlayList
  const getPlayListDetails = (teacherId) => {
    // setLoading(true);
    axiosInstance
      .get("/playlist/lists?teacher_id=" + teacherId)
      .then((res) => {
        setPlayListDetails(manipulateDataForPost(res.data.playlists));
        // setPlayListDetails(res.data.playlists);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickOfPlaylist = (playLisTitle, playListFeeds, playListId) => {
    setSelectedPlaylist(playLisTitle);
    setSelectedPlayListFeeds(playListFeeds);
    setSelectedPlayListId(playListId);
  };

  const onActionCallBack = (actionStatus, actionType, additionalValue) => {
    const feedId = additionalValue.requestData.post_id;
    if (actionStatus === 1) {
      let __actionedFeedIndex = null;
      let __selectedPlayListFeeds = selectedPlayListFeeds.filter(function (
        item,
        index
      ) {
        if (item.id === feedId) {
          __actionedFeedIndex = index;
          return item;
        }
      });
      __selectedPlayListFeeds = __selectedPlayListFeeds[0];
      if (actionType === "favorite") {
        __selectedPlayListFeeds.favourite_save = {};
      } else if (actionType === "un-favorite") {
        __selectedPlayListFeeds.favourite_save = null;
      } else if (actionType === "save") {
        __selectedPlayListFeeds.post_save = {};
      } else if (actionType === "un-save") {
        __selectedPlayListFeeds.post_save = null;
      }
      let tempSelectedPlayListFeeds = selectedPlayListFeeds;
      tempSelectedPlayListFeeds[__actionedFeedIndex] = __selectedPlayListFeeds;
      setSelectedPlayListFeeds(selectedPlayListFeeds);
    }
  };

  const onActionCallBackOfPlayList = (playListId, actionType) => {
    let __actionedPlaylistIndex = null;
    let _actionedPlayList = playListDetails.filter((item, index) => {
      if (item.id == playListId) {
        __actionedPlaylistIndex = index;
        return item.id == playListId;
      }
    });
    _actionedPlayList = _actionedPlayList[0];
    if (actionType == "favorite") {
      _actionedPlayList.studentplaylist_save = {};
    } else if (actionType == "un-favorite") {
      _actionedPlayList.studentplaylist_save = null;
    }
    playListDetails[__actionedPlaylistIndex] = _actionedPlayList;
    setPlayListDetails(playListDetails);
  };

  useEffect(() => {
    if (teacherId) {
      // setLoading(true); // Set loading to true before fetching data
      getPlayListDetails(teacherId);
    }
  }, [teacherId]);

  return (
    <div className={classes.playListData}>
      {selectedPlaylist ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#c4c4c4",
              borderRadius: "10px",
              padding: ".5rem",
            }}
          >
            <h1>{selectedPlaylist}</h1>
            <BackIcon
              fontSize="large"
              //onClick={() => setSelectedPlaylist(undefined)}
              onClick={() => onClickOfPlaylist(undefined, [])}
              style={{
                cursor: "pointer",
                fill: "white",
                borderRadius: "50%",
                background: "black",
              }}
            />
          </div>
          {selectedFeedId > 0 ? (
            <LectureDetailsContainer
              setLectureDetailsOpen={viewSelectedFeedDetail}
              selectedFeedId={selectedFeedId}
            />
          ) : (
            <LecturesContainer
              onActionCallBack={onActionCallBack}
              lectures={selectedPlayListFeeds}
              lectureItemClickHandler={viewSelectedFeedDetail}
              onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
              blockWidth={selectedFeedId > 0 ? "100%" : "50%"}
            />
          )}
        </>
      ) : (
        <>
          {loading ? (
            <div
              style={{
                padding: "1rem",
                textAlign: "center",
                fontSize: "2rem",
                opacity: "0.3",
              }}
            >
              {/* Loading... */}
            </div>
          ) : !playListDetails || playListDetails.length === 0 ? (
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <div
                style={{
                  padding: "1rem",
                  textAlign: "center",
                  fontSize: "2rem",
                  opacity: "0.3",
                }}
              >
                No playlist available
              </div>
              {identity.userId === teacherId && (
                <Button
                  variant="contained"
                  style={customStyles.submitBtn}
                  onClick={() => setPlaylistModalOpen(true)}
                >
                  Create playlist now
                </Button>
              )}
            </div>
          ) : (
            <div>
              {playListDetails.map((item) => (
                <div style={{ position: "relative" }}>
                  <div
                    onClick={() =>
                      onClickOfPlaylist(
                        item.title,
                        item._manipulatedFeedData,
                        item.id
                      )
                    }
                  >
                    <div
                      key={item.id}
                      style={{
                        padding: "1rem",
                        backgroundColor: "#c4c4c4",
                        borderRadius: "5px",
                        fontSize: "17px",
                        marginBottom: ".5rem",
                        cursor: "pointer",
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{item.title}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      right: "12px",
                      top: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <>
                        {identity.userType === "student" && (
                          <StudentPlayListFavuriteMenu
                            loading={loading}
                            setLoading={setLoading}
                            identity={identity}
                            setToast={setToast}
                            playListId={item.id}
                            teacherId={item.teacher_id}
                            postTypeFavourite={
                              item.studentplaylist_save == null
                            }
                            postTypeUnFavourite={
                              item.studentplaylist_save !== null
                            }
                            onActionCallBackOfPlayList={
                              onActionCallBackOfPlayList
                            }
                            playListData={item}
                          />
                        )}
                      </>
                    </div>
                  </div>
                </div>
              ))}
              {identity.userType !== "student" &&
                identity.userId == teacherId &&
                playListDetails?.length > 0 && (
                  <AddBoxIcon
                    style={{ fontSize: 50, cursor: "pointer" }}
                    onClick={() => setPlaylistModalOpen(true)}
                  />
                )}
            </div>
          )}
        </>
      )}
      <AddPlayListModal
        open={playlistModalOpen}
        setOpen={setPlaylistModalOpen}
        createPlayList={createPlayList}
        title={title}
        setTitle={setTitle}
        loading={loading}
        playListDetails={playListDetails}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default Playlist;
