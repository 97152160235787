import React, { useState, useContext, useEffect } from "react";
import Controls from "./Controls";
import LecturesContainer from "../../containers/LecturesContainer";
import classes from "./StudentProfile.module.css";
import StudentDetails from "./StudentDetails";
import Playlist from "./Playlist";
import FavoriteTeachers from "./FavoriteTeachers";
import { UIContext } from "../../contexts/UIContext";
import { AuthContext } from "../../contexts/AuthContext";
import axiosInstance from "../../helpers/axiosInstance";
import CF from "../../utils/CommonFunction";
import { useParams, useNavigate } from "react-router-dom";
import LectureDetailsContainer from "../../containers/LectureDetailsContainer";

const StudentProfile = () => {
  const [activeControl, setActiveControl] = useState("lectures");
  const [studentFavoritePostList, setStudentFavoritePostList] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const { setLoading } = useContext(UIContext);
  const { identity } = useContext(AuthContext);
  const navigate = useNavigate();
  const { tabName, slug } = useParams();
  const [selectedFeedId, setSelectedFeedId] = useState(0);
  const [loadingStudentFavourites, setLoadingStudentFavourites] =
    useState(false);

  const viewSelectedFeedDetail = (feedId) => {
    setSelectedFeedId(feedId);
  };

  const getStudentFavoritePostList = () => {
    // setLoading(true);
    setLoadingStudentFavourites(true);
    axiosInstance
      .get("/post/save?type=favorite&user_type=" + identity.userType)
      .then((res) => {
        setStudentFavoritePostList(
          CF.assignRandomColorCodeInArray(res.data.feeds)
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoadingStudentFavourites(false);
      });
  };

  const onActionCallBack = (actionStatus, actionType, additionalValue) => {
    if (actionStatus === 1) {
      if (actionType === "favorite") {
        getStudentFavoritePostList();
      } else if (actionType === "favorite") {
        // console.log(additionalValue.feedType);
      } else if (actionType === "un-favorite") {
        getStudentFavoritePostList("following-feed");
      }
    }
  };

  useEffect(() => {
    getStudentFavoritePostList();
    setActiveControl(tabName);
  }, []);

  const onChangeActiveTabs = (tabName) => {
    if (activeControl === "playlist") {
      setSelectedPlaylist(null);
    }

    if (slug) {
      navigate({
        pathname: "/student-profile/" + tabName + "/" + slug,
      });
    } else {
      navigate({
        pathname: "/student-profile/" + tabName,
      });
    }
    setActiveControl(tabName);
  };

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <StudentDetails />
      </div>
      <div className={classes.center}>
        <Controls
          activeControl={activeControl}
          setActiveControl={setActiveControl}
          onChangeActiveTabs={onChangeActiveTabs}
        />
      </div>
      <div className={classes.right}>
        {activeControl === "lectures" && (
          <div className={classes.scrollView}>
            {selectedFeedId > 0 ? (
              <LectureDetailsContainer
                setLectureDetailsOpen={viewSelectedFeedDetail}
                selectedFeedId={selectedFeedId}
              />
            ) : (
              <LecturesContainer
                lectureItemClickHandler={viewSelectedFeedDetail}
                lectures={studentFavoritePostList}
                onActionCallBack={onActionCallBack}
                blockWidth={"50%"}
                isLoading={loadingStudentFavourites}
              />
            )}
          </div>
        )}

        {activeControl === "playlist" && (
          <div className={classes.playlistMainDiv}>
            <Playlist
              selectedPlaylist={selectedPlaylist}
              setSelectedPlaylist={setSelectedPlaylist}
            />
          </div>
        )}
        {activeControl === "favorite-teachers" && (
          <div className={classes.playlistMainDiv}>
            <FavoriteTeachers />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentProfile;
