import React, { useEffect } from "react";
import classes from "./PageNotFound.module.css";
import CF from "../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  let navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const accessType = urlParams.get("loginvautoRedirectOnLoginFor");
  const token = JSON.parse(localStorage.getItem("userIdentity"));

  const backToPrevoiusPage = () => {
    if (token?.userType == "teacher") {
      navigate("/dashboard");
    } else if (token?.userType === "super") {
      navigate("/super-admin-dashboard");
    } else if (token?.userType == "student") {
      navigate("/dashboard");
    } else if (token?.userType == "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (accessType) {
      CF.lastPageAccess.delete.single(accessType);
    }
  }, []);

  return (
    <div className={classes.container}>
      <div>
        <h1 className={classes.noText}>404</h1>
        <p className={classes.text}>Page Not Found</p>
        <div className={classes.btnMainDiv}>
          <button
            className={classes.goBackBtn}
            onClick={() => backToPrevoiusPage()}
          >
            Go Back To {!token?.accessToken ? "Home" : "Dashboard"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
