import { Grid, TextField, Chip } from "@material-ui/core";
import React, { useState, useContext, useEffect, useRef } from "react";
import axiosInstance from "../../../helpers/axiosInstance";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { UIContext } from "../../../contexts/UIContext";
import classes from "./Register.module.css";
import Toast from "../../../components/ui/toast/Toast";
import CF from "../../../utils/CommonFunction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

const schema = yup.object({
  name: yup
    .string()
    .min(5, "Institution Name is too short")
    .max(200, "Institution Name is too long")
    .required("Institution Name is required"),
  email: yup
    .string()
    .max(100, "Email is too long")
    .required("Email is required"),
  address: yup
    .string()
    .max(200, "Address of Institution is too long")
    .required("Address of Institution is required"),
  // domain: yup
  //    .array()
  //    .min(1, "Add atleast 1 domain")
  //    .required("Domain is required"),
  username: yup
    .string()
    .max(100, "Username is too long")
    .required("Username is required"),
  password: yup
    .string()
    .max(100, "Password is too long")
    .required("Password Name is required"),
});

const Register = () => {
  const { inviteCode } = useParams();
  const { identity } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [googleLoading, setGoogleLoading] = useState(false);
  const [toast, setToast] = useState();

  const { setIdentity } = useContext(AuthContext);
  const { setLoading } = useContext(UIContext);
  let navigate = useNavigate();

  useEffect(() => {
    //use login or not
    if (!identity.isLoggedIn) {
      if (JSON.parse(localStorage.getItem("userIdentity"))) {
        setToast(CF.alert.success("Your current account logout successfully."));
      }
      localStorage.removeItem("userIdentity");
    }
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      name: "",
      email: "",
      address: "",
      domain: [],
      username: "",
      password: "",
    },
  });

  const [domianList, setDomianList] = useState([]);
  const [domainErrorMessage, setDomainErrorMessage] = useState("");
  const [wasDomainFocus, setWasDomainFocus] = useState(false);
  const domainRef = useRef();

  function removeTag(index) {
    setDomianList(domianList.filter((el, i) => i !== index));
  }

  function isDomain(arg) {
    let regexDomain = /^\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (arg.match(regexDomain)) {
      return true;
    } else {
      return false;
    }
  }

  function handleKeyDownDomain(e, type) {
    if (e.key !== "Enter" && e.key !== "Tab" && type !== "Leave") return;
    const value = e.target.value.trim();
    if (!isDomain(value)) {
      setDomainErrorMessage("Please enter valid domain. (e.g: schooled.info)");
    } else if (domianList.includes(value)) {
      setDomainErrorMessage(
        "You cannot enter same an domain again. Please, enter diffrent domain."
      );
    } else {
      setDomianList([...domianList, value]);
      e.target.value = "";
      setDomainErrorMessage("");
    }
  }

  function validateDomain() {
    let message = "";
    if (
      domainRef.current.value.trim() !== "" &&
      !isDomain(domainRef.current.value.trim())
    ) {
      message = "Please enter valid domain. (e.g: schooled.info)";
    } else if (domianList.length === 0) {
      message = "Please enter atleast one domain.";
    }
    setDomainErrorMessage(message);
    return message === "";
  }

  useEffect(() => {
    if (wasDomainFocus) {
      validateDomain();
    }
  }, [wasDomainFocus]);

  useEffect(() => {
    if (!CF.isObjectEmpty(errors)) {
      validateDomain();
    }
  }, [errors]);

  const onSubmit = (data) => {
    if (validateDomain()) {
      data = {
        ...data,
        domain: JSON.stringify(domianList),
      };
      setLoading(true);
      axiosInstance
        .post(`/admin/register/${inviteCode}`, data)
        .then((res) => {
          if (res?.data?.status === false) {
            setToast(CF.alert.error(res.data.msg));
          } else {
            alert("Success, please login");
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err.response && err.response.data) {
            setToast({
              status: "error",
              title: "Not Registered",
              body: err.response.data.msg,
            });
          }
        });
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item lg={6} md={6} xs={12} sm={12} className={classes.left}>
          <div className={classes.form}>
            <form>
              <div className={classes.inputWrapper}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Institution Name"
                      variant="outlined"
                      placeholder="Enter your institution name"
                      {...field}
                      error={!!errors.name}
                      fullWidth
                    />
                  )}
                />
                <p className="error-text">{errors.name?.message}</p>
              </div>

              <div className={classes.inputWrapper}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Email"
                      variant="outlined"
                      placeholder="Enter your email(You must need to enter same an email address, where you recived an invtiation.)"
                      {...field}
                      error={!!errors.email}
                      fullWidth
                    />
                  )}
                />
                <p className="error-text">{errors.email?.message}</p>
              </div>

              <div className={classes.inputWrapper}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Address of Institution"
                      variant="outlined"
                      placeholder="Enter your address"
                      {...field}
                      error={!!errors.address}
                      fullWidth
                    />
                  )}
                />
                <p className="error-text">{errors.address?.message}</p>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <TextField
                  label="Domain"
                  variant="outlined"
                  placeholder="Enter your domain (e.g: schooled.info)"
                  error={domainErrorMessage != ""}
                  fullWidth
                  onKeyDown={(e) => handleKeyDownDomain(e)}
                  onBlur={validateDomain}
                  onFocus={() => setWasDomainFocus(true)}
                  inputRef={domainRef}
                  onMouseLeave={(e) => {
                    if (e.target.value) {
                      handleKeyDownDomain(e, "Leave");
                    }
                  }}
                  InputProps={{
                    startAdornment: domianList.map((item, index) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        className={classes.chip}
                        onDelete={() => removeTag(index)}
                      />
                    )),
                  }}
                />
                <p className="error-text">{domainErrorMessage}</p>
              </div>
              <div className={classes.inputWrapper}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Username"
                      variant="outlined"
                      placeholder="Enter your username"
                      {...field}
                      error={!!errors.username}
                      fullWidth
                    />
                  )}
                />
                <p className="error-text">{errors.username?.message}</p>
              </div>

              <div className={classes.inputWrapper}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="password"
                      variant="outlined"
                      placeholder="Enter your password"
                      {...field}
                      error={!!errors.password}
                      fullWidth
                      type="password"
                    />
                  )}
                />
                <p className="error-text">{errors.password?.message}</p>
              </div>
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                className={classes.loginBtn}
              >
                Register
              </button>
            </form>
          </div>
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={12} className={classes.right}>
          <div>
            <h1>SCHOOLED</h1>
            <h1>EDUCATIONAL</h1>
            <h1>CONTENT</h1>
          </div>
        </Grid>
      </Grid>
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </>
  );
};

export default Register;
