import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const customStyles = {
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 24px",
  },
  dialogTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    margin: 0,
  },
  closeButton: {
    cursor: "pointer",
  },
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    marginTop: "17px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  inputField: {
    marginBottom: "20px",
  },
  playlistTitle: {
    marginTop: "20px",
    marginBottom: "10px",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  noPlaylist: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    fontSize: "1.6rem",
    opacity: "0.3",
  },
  playlistList: {
    listStyle: "none",
    paddingLeft: 0,
  },
  playlistItem: {
    backgroundColor: "#f5f5f5",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  playlistItemHover: {
    backgroundColor: "#e0e0e0",
  },
};

export default function AddPlayListModal({
  open,
  setOpen,
  createPlayList,
  title,
  setTitle,
  loading,
  playListDetails,
  onClickOfPlayList,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <Box style={customStyles.modalHeader}>
        <DialogTitle id="alert-dialog-title" style={customStyles.dialogTitle}>
          Add Playlist
        </DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setOpen(false)}
          aria-label="close"
          style={customStyles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <form>
          <TextField
            variant="outlined"
            fullWidth
            label="Playlist Name"
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
            inputProps={{
              maxLength: 200,
            }}
            style={customStyles.inputField}
          />
          <Button
            variant="contained"
            fullWidth
            style={customStyles.submitBtn}
            onClick={createPlayList}
            disabled={loading || title.trim() === ""}
          >
            Add Playlist
          </Button>
        </form>

        <div style={customStyles.playlistTitle}>
          {playListDetails?.length > 0 ? "Playlist" : ""}
        </div>

        {playListDetails?.length > 0 ? (
          <ul style={customStyles.playlistList}>
            {playListDetails?.map((item) => (
              <li
                key={item.id}
                style={customStyles.playlistItem}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    customStyles.playlistItemHover.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    customStyles.playlistItem.backgroundColor)
                }
                onClick={(e) => onClickOfPlayList(e, item.id)}
              >
                {item?.title}
              </li>
            ))}
          </ul>
        ) : (
          <div style={customStyles.noPlaylist}>No Playlist</div>
        )}
      </DialogContent>
    </Dialog>
  );
}
