import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/schooled_logo.png";
import classes from "./NavbarIn.module.css";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import AccountIcon from "@material-ui/icons/AccountBoxOutlined";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, Tabs, Tab } from "@material-ui/core";
import CF from "../../utils/CommonFunction";

const NavbarIn = ({ path }) => {
  const { identity, setIdentity } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);
  const primaryColor = "var(--primary-color)";

  const getInitialTab = () => {
    const storedTab = localStorage.getItem("selectedTab");
    if (storedTab !== null) {
      return parseInt(storedTab, 10); // Parse as an integer
    } else if (location.pathname.includes("profile")) {
      return 1;
    } else {
      return 0;
    }
  };

  const [selectedTab, setSelectedTab] = useState(getInitialTab);

  const handleSearch = (e) => {
    if (!searchText) {
      return;
    }
    if (e.keyCode == 13) {
      navigate(`/search/${searchText}`);
    }
  };

  const logout = () => {
    setIdentity({
      isLoggedIn: false,
      username: "",
      userType: "",
    });
    localStorage.removeItem("userIdentity");
    navigate("/");
  };

  useEffect(() => {
    if (path !== "search") {
      setSearchText("");
    }
  }, [path]);

  useEffect(() => {
    if (path === "search") {
      setSearchText(location.pathname.split("/").pop());
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedTab", newValue); // Store the selected tab in localStorage

    if (newValue === 0) {
      navigate("/dashboard");
    } else if (newValue === 1) {
      navigate(
        identity.userType === "student"
          ? "/student-profile/lectures"
          : `teacher-profile/${identity.userId}/lectures`
      );
    }
  };

  useEffect(() => {
    // On mount, synchronize the tab with the current path
    const currentPath = location.pathname;
    if (
      currentPath.includes("/profile") ||
      currentPath.includes("/lectures") ||
      currentPath.includes("/playlist") ||
      currentPath.includes("/create-post") ||
      currentPath.includes("/favorite-teachers")
    ) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [location.pathname]);

  return (
    <>
      <header className={classes.navbar}>
        <div className={classes.left}>
          <Link to="/dashboard">
            <img src={logo} className={classes.logo} alt="Schooled" />
          </Link>
          <div className={classes.desktopSearchBar}>
            <div className={classes.searchBar}>
              <SearchIcon fontSize="large" className={classes.searchIcon} />
              <input
                type="text"
                placeholder="Type and hit ENTER (Search teacher & lectures)"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value === "") {
                    navigate("/dashboard");
                  }
                }}
                title={"Search here"}
                onKeyDown={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className={classes.menuBtn}>
          <IconButton onClick={toggleMenu}>
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <nav className={classes.navDesktop}>
          <ul className={classes.navList}>
            <li className={classes.navItem}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: primaryColor,
                  },
                }}
              >
                <Tab
                  label={
                    <div className={classes.tabContent}>
                      <HomeIcon fontSize="medium" />
                      <span
                        style={{
                          marginBottom: "-3px",
                        }}
                      >
                        Home
                      </span>
                    </div>
                  }
                  title="Home"
                  style={{
                    color: selectedTab === 0 ? "var(--primary-color)" : "",
                    minHeight: "48px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
                <Tab
                  label={
                    <div className={classes.tabContent}>
                      <AccountIcon fontSize="medium" />
                      <span
                        style={{
                          marginBottom: "-2 px",
                        }}
                      >
                        Profile
                      </span>
                    </div>
                  }
                  title="Profile"
                  style={{
                    color: selectedTab === 1 ? "var(--primary-color)" : "",
                    minHeight: "48px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </Tabs>
            </li>
            <li className={classes.navItem}>
              <Button
                variant="contained"
                className={classes.actionBtn}
                onClick={logout}
                title="Logout"
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "#fff",
                  borderRadius: "12px",
                  height: "40px",
                  marginTop: "3px",
                  minWidth: "40px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color-hover)",
                  },
                }}
              >
                <LogoutIcon fontSize="medium" />
              </Button>
            </li>
          </ul>
        </nav>
      </header>
      <div className={classes.mobileSearchBar}>
        <div className={classes.searchBar}>
          <SearchIcon fontSize="large" className={classes.searchIcon} />
          <input
            type="text"
            placeholder="Type and hit ENTER (Search teacher & lectures)"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                navigate("/dashboard");
              }
            }}
            title={"Search here"}
            onKeyDown={(e) => handleSearch(e)}
          />
        </div>
      </div>
      {menuOpen && (
        <nav className={classes.menu} onClick={closeMenu}>
          <ul>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <HomeIcon
                  fontSize="medium"
                  style={{
                    marginRight: "10px",
                    // marginBottom: "5px",
                    color:
                      location.pathname === "/dashboard" ? primaryColor : "",
                  }}
                />
                <Link
                  to="/dashboard"
                  className={classes.navLink}
                  style={{
                    color:
                      location.pathname === "/dashboard" ? primaryColor : "",
                  }}
                >
                  Home
                </Link>
              </div>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AccountIcon
                  fontSize="medium"
                  style={{
                    marginRight: "10px",
                    // marginBottom: "5px",
                    color:
                      location.pathname.startsWith("/teacher-profile") ||
                      location.pathname.startsWith("/student-profile") ||
                      location.pathname.startsWith("/create-post")
                        ? primaryColor
                        : "",
                  }}
                />
                <Link
                  to={
                    identity.userType === "student"
                      ? "/student-profile" + "/lectures"
                      : "teacher-profile/" + identity.userId + "/lectures"
                  }
                  className={classes.navLink}
                  style={{
                    color:
                      location.pathname.startsWith("/teacher-profile") ||
                      location.pathname.startsWith("/student-profile") ||
                      location.pathname.startsWith("/create-post")
                        ? primaryColor
                        : "",
                  }}
                >
                  Profile
                </Link>
              </div>
            </li>
            <li onClick={logout} style={{ cursor: "pointer" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon fontSize="medium" style={{ marginRight: "10px" }} />
                Logout
              </div>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

export default NavbarIn;
