import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import axiosInstance from "../../helpers/axiosInstance";
import CF from "../../utils/CommonFunction";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export default function StudentPlayListFavuriteMenu({
  setLoading,
  identity,
  setToast,
  playListId,
  teacherId,
  onActionCallBackOfPlayList,
  postTypeFavourite,
  postTypeUnFavourite,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const playListAddInFavorites = (e) => {
    handleClose();
    setLoading(true);
    let requestData = {
      playlist_id: playListId,
      teacher_id: teacherId,
      student_id: identity.userId,
    };
    axiosInstance
      .post("/playlist/student-save", requestData)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          onActionCallBackOfPlayList(playListId, "favorite");
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error(
            "Something went wrong, while adding playlist in favorites."
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const playListRemoveInFavorites = (e) => {
    handleClose();
    setLoading(true);
    let requestData = {
      playlist_id: playListId,
      teacher_id: teacherId,
      student_id: identity.userId,
    };
    axiosInstance
      .post("/playlist/student-unsave", requestData)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          onActionCallBackOfPlayList(playListId, "un-favorite");
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error(
            "Something went wrong, while playlist in un-favorites."
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {(postTypeFavourite || postTypeUnFavourite) && (
        <>
          <MoreVertIcon fontSize="small" onClick={handleClick} />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              style: {
                display: "flex",
                flexDirection: "column",
              },
            }}
            PaperProps={{
              sx: {
                width: postTypeUnFavourite ? "220px" : "125px",
              },
            }}
          >
            {postTypeFavourite && (
              <MenuItem
                onClose={handleClose}
                onClick={(e) => playListAddInFavorites(e)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "8px 16px",
                  gap: "8px",
                  lineHeight: "20px",
                }}
              >
                <FavoriteBorderIcon
                  fontSize="small"
                  style={{
                    fontSize: "20px",
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    marginRight: "42px",
                  }}
                >
                  Favorites
                </span>
              </MenuItem>
            )}
            {postTypeUnFavourite && (
              <MenuItem
                onClose={handleClose}
                onClick={(e) => playListRemoveInFavorites(e)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "8px 16px",
                  gap: "8px",
                  lineHeight: "20px",
                }}
              >
                <RemoveCircleOutlineIcon
                  fontSize="small"
                  style={{
                    fontSize: "20px",
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  Remove from favorites
                </span>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </div>
  );
}
