import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import PrivateIcon from "@material-ui/icons/PersonOutlineOutlined";
import PublicIcon from "@material-ui/icons/PeopleAltOutlined";
import InstitutionIcon from "@material-ui/icons/AccountBalanceOutlined";
import ClosedIcon from "@material-ui/icons/Close";
import UploadIcon from "@material-ui/icons/CloudUpload";
import PlaylistIcon from "@material-ui/icons/PlaylistAdd";
import classes from "./CreatePost.module.css";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axiosInstance from "../../helpers/axiosInstance";
import { UIContext } from "../../contexts/UIContext";
import PostPlayListingModal from "../Dialogs/PostPlayListingModal";
import CF from "../../utils/CommonFunction";
import Toast from "../../components/ui/toast/Toast";
import { AuthContext } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";

const urls =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;

const schema = yup.object({
  title: yup
    .string()
    .max(200, "Title is too long")
    .required("Title is required"),
  ytVideoId: yup
    .string()
    .matches(urls, "Please enter correct URL")
    .max(100, "YouTube Video URL is too long")
    .required("Youtube Video URL is required"),
  keyWords: yup.string().max(500, "Keywords is too long"),
  tags: yup.string().max(600, "Tags is too long"),
});

const CreatePost = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setLoading } = useContext(UIContext);
  const [postType, setPostType] = useState("public");
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const [playListDetails, setPlayListDetails] = useState([]);
  const [commentType, setCommentType] = useState("public");
  const [toast, setToast] = useState();
  const { identity } = useContext(AuthContext);
  const [feedFormData, setFeedFormData] = useState({});
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      title: "",
      // caption: "",
      ytVideoId: "",
      keyWords: "",
      tags: "",
      playlist_id: "",
      is_thumbnail: "0",
      description: "",
    },
  });

  //Set useEffect based on popup open
  useEffect(() => {
    if (playlistModalOpen) {
      getPlayListDetails(identity.userId);
    }
  }, [playlistModalOpen]);

  //Listing API For PlayList
  const getPlayListDetails = (teacherId) => {
    axiosInstance
      .get("/playlist/lists?teacher_id=" + teacherId)
      .then((res) => {
        setPlayListDetails(res.data.playlists);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickOfThumbnailVideo = (feedFormData) => {
    onSubmit({
      ...feedFormData,
      is_thumbnail: 1,
    });
  };

  const onClickOfAddToPlaylist = (feedFormData) => {
    setFeedFormData(feedFormData);
    setPlaylistModalOpen(true);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const postData = {
      ...data,
      ytLink: data.ytVideoId.replace(
        "https://www.youtube.com/watch?v=",
        "https://www.youtube.com/embed/"
      ),
      visibility: postType,
      commentType,
      // video_id: data.ytVideoId,
      video_id: data.ytVideoId.replace("https://www.youtube.com/watch?v=", ""),
    };
    delete postData["ytVideoId"];
    axiosInstance
      .post("/teacher/post", postData)
      .then((res) => {
        if (res.data.status === true) {
          setToast(CF.alert.success(res.data.msg));
          navigate("/teacher-profile/" + identity.userId + "/lectures");
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        console.error(err);
        setToast(CF.alert.error("Something went wrong, while creating post."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={isSmall ? 0 : 5} className={classes.container}>
      <Grid item lg={6} sm={12} md={12}>
        <div className={classes.togglers}>
          <div className={classes.toggleItem}>
            <h3 style={{ display: "flex", marginLeft: "7px" }}>Who can see?</h3>
            <ToggleButtonGroup
              color="primary"
              value={postType}
              exclusive
              onChange={(e, val) => {
                if (val !== null) setPostType(val); // Prevent deselect on double-click
              }}
              className={classes.toogleBtnOne}
            >
              <ToggleButton
                value="public"
                style={{
                  padding: "15px",
                  marginRight: "8px",
                  border: "none",
                }}
              >
                <PublicIcon className={classes.icon} />
                <span className={classes.textItem}>Public</span>
              </ToggleButton>
              <ToggleButton
                value="private"
                style={{
                  padding: "15px",
                  marginRight: "8px",
                  border: "none",
                }}
              >
                <PrivateIcon className={classes.icon} />
                <span className={classes.textItem}>Private</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div className={classes.toggleItemComment}>
            <h3 className={classes.commenText}>Comments</h3>
            <ToggleButtonGroup
              color="primary"
              value={commentType}
              exclusive
              onChange={(e, val) => {
                if (val !== null) setCommentType(val); // Prevent deselect on double-click
              }}
              className={classes.toogleBtnTwo}
            >
              <ToggleButton
                value="public"
                style={{
                  padding: "15px",
                  marginRight: "8px",
                  border: "none",
                }}
              >
                <PublicIcon className={classes.icon} />
                <span className={classes.textItem}>Public</span>
              </ToggleButton>
              <ToggleButton
                value="institution"
                style={{
                  padding: "15px",
                  marginRight: "8px",
                  border: "none",
                }}
              >
                <InstitutionIcon className={classes.icon} />
                <span className={classes.textItem}>Institution</span>
              </ToggleButton>
              <ToggleButton
                value="closed"
                style={{
                  padding: "15px",
                  marginRight: "8px",
                  border: "none",
                }}
                onClick={() =>
                  navigate("/teacher-profile/" + identity.userId + "/lectures")
                }
              >
                <ClosedIcon className={classes.icon} />
                <span className={classes.textItem}>Closed</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className={classes.inputForm}>
          <div className={classes.input}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Title"
                  variant="outlined"
                  placeholder="Enter your Title"
                  {...field}
                  error={!!errors.title}
                  fullWidth
                />
              )}
            />
            <p className="error-text">{errors.title?.message}</p>
          </div>
          <div className={classes.input}>
            <Controller
              name="ytVideoId"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Youtube Video URL (e.g: https://www.youtube.com/watch?v=FpMXzChvOaw)"
                  variant="outlined"
                  placeholder="Enter your Youtube Video URL (e.g: https://www.youtube.com/watch?v=FpMXzChvOaw)"
                  {...field}
                  error={!!errors.ytVideoId}
                  fullWidth
                />
              )}
            />
            <p className="error-text">{errors.ytVideoId?.message}</p>
          </div>
          <div className={classes.input}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Tags"
                  variant="outlined"
                  placeholder="Enter your Tags"
                  {...field}
                  error={!!errors.tags}
                  fullWidth
                  minRows={5}
                />
              )}
            />
            <p className="error-text">{errors.tags?.message}</p>
          </div>
          <div className={classes.input}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Description"
                  variant="outlined"
                  placeholder="Enter your description"
                  {...field}
                  error={!!errors.description}
                  fullWidth
                  rows={10}
                  multiline
                />
              )}
            />
            <p className="error-text">{errors.description?.message}</p>
          </div>
        </div>
      </Grid>
      <Grid item lg={6} sm={12} md={12}>
        {watch("ytVideoId").length > 0 ? (
          <iframe
            className={classes.youtubeVideo}
            src={`${watch("ytVideoId").replace(
              "https://www.youtube.com/watch?v=",
              "https://www.youtube.com/embed/"
            )}`}
          ></iframe>
        ) : (
          <div className={classes.video}>
            <span>+ Video</span>
          </div>
        )}
        <div className={classes.actions}>
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            startIcon={<UploadIcon />}
            style={{ marginBottom: "1rem" }}
            onClick={handleSubmit(onClickOfThumbnailVideo)}
          >
            Upload Video
          </Button>
          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: "#87CA87" }}
            startIcon={<PlaylistIcon />}
            onClick={handleSubmit(onClickOfAddToPlaylist)}
          >
            Add to Playlist
          </Button>
        </div>
        <h6 className={classes.supportText}>
          Contact teachersupport@schooled.info for help
        </h6>
      </Grid>
      <PostPlayListingModal
        playlistModalOpen={playlistModalOpen}
        setOpen={setPlaylistModalOpen}
        playListDetails={playListDetails}
        getPlayListDetails={getPlayListDetails}
        id={id}
        handleSubmit={onSubmit}
        feedFormData={feedFormData}
        setPlayListDetails={setPlayListDetails}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </Grid>
  );
};

export default CreatePost;
