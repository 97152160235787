import React from "react";
import classes from "./OurMission.module.css";
import ourMission from "../../assets/images/our-mission.png";
import ourMissionContent from "../../assets/images/our-mission-content.png";

const OurMission = () => {
  return (
    <div className={classes.main}>
      <img src={ourMission} className={classes.logo} alt="our-mission" />
      <div className={classes.ourMissionDiv}>
        <img src={ourMissionContent} alt="our-mission-content"  loading="lazy"/>
      </div>
    </div>
  );
};

export default OurMission;
