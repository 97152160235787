import React from "react";

const SignUp = () => {
  return (
    <div style={{ padding: "3rem" }}>
      <h3
        style={{
          fontWeight: 500,
          lineHeight: "36px",
          letterSpacing: " 0em",
          marginTop: "23px",
        }}
      >
        SignUp
      </h3>
    </div>
  );
};

export default SignUp;
