import React, { useState, useContext, useEffect } from "react";
import BackIcon from "@material-ui/icons/ArrowBack";
import LecturesContainer from "../../containers/LecturesContainer";
import axiosInstance from "../../helpers/axiosInstance";
import { UIContext } from "../../contexts/UIContext";
import Toast from "../../components/ui/toast/Toast";
import CF from "../../utils/CommonFunction";
import { AuthContext } from "../../contexts/AuthContext";
import LectureDetailsContainer from "../../containers/LectureDetailsContainer";
import StudentPlayListFavuriteMenu from "./StudentPlayListFavuriteMenu";

const Playlist = ({
  selectedPlaylist,
  setSelectedPlaylist,
  onCallbackOfDeleteFeedPost,
}) => {
  const [playListDetails, setPlayListDetails] = useState([]);
  const { loading, setLoading } = useContext(UIContext);
  const [toast, setToast] = useState();
  const { identity } = useContext(AuthContext);
  const [selectedPlayListFeeds, setSelectedPlayListFeeds] = useState([]);
  const [selectedFeedId, setSelectedFeedId] = useState(0);
  const [loadingPlayList, setLoadingPlayList] = useState(false);

  const viewSelectedFeedDetail = (feedId) => {
    setSelectedFeedId(feedId);
  };

  useEffect(() => {
    getStudentPlayList();
  }, []);

  const manipulateDataForPost = (playlistData) => {
    for (let i = 0; i < playlistData.length; i++) {
      let _manipulatedFeed = [];
      if (playlistData[i].hasOwnProperty("post_saves")) {
        let singleFeed = playlistData[i]["post_saves"];
        for (let j = 0; j < singleFeed.length; j++) {
          let singlePost = singleFeed[j].post;
          _manipulatedFeed.push({
            ...singlePost,
            teacher: playlistData[i].teachers[0],
          });
        }
      }
      playlistData[i]["_manipulatedFeedData"] =
        CF.assignRandomColorCodeInArray(_manipulatedFeed);
    }
    return playlistData;
  };

  const getStudentPlayList = () => {
    setLoading(true);
    setLoadingPlayList(true);
    axiosInstance
      .get("/playlist/student-lists")
      .then((res) => {
        setPlayListDetails(manipulateDataForPost(res.data.playlists));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoadingPlayList(false);
      });
  };

  const onClickOfPlaylist = (playLisTitle, playListFeeds) => {
    setSelectedPlaylist(playLisTitle);
    setSelectedPlayListFeeds(playListFeeds);
  };

  const onActionCallBack = (actionStatus, actionType, additionalValue) => {
    const feedId = additionalValue.requestData.post_id;
    if (actionStatus === 1) {
      let __actionedFeedIndex = null;
      let __selectedPlayListFeeds = selectedPlayListFeeds.filter(function (
        item,
        index
      ) {
        if (item.id === feedId) {
          __actionedFeedIndex = index;
          return item;
        }
      });
      if (actionType === "favorite") {
        __selectedPlayListFeeds.favourite_save = {};
      } else if (actionType === "un-favorite") {
        __selectedPlayListFeeds.favourite_save = null;
      } else if (actionType === "un-favorite") {
        __selectedPlayListFeeds.favourite_save = null;
      }
      let tempSelectedPlayListFeeds = selectedPlayListFeeds;
      tempSelectedPlayListFeeds[__actionedFeedIndex] = __selectedPlayListFeeds;
      setSelectedPlayListFeeds(selectedPlayListFeeds);
    }
  };

  const onActionCallBackOfPlayList = (playListId, actionType) => {
    let __actionedPlaylistIndex = null;
    let _actionedPlayList = playListDetails.filter((item, index) => {
      if (item.id == playListId) {
        __actionedPlaylistIndex = index;
        return item.id == playListId;
      }
    });
    _actionedPlayList = _actionedPlayList[0];
    if (actionType == "favorite") {
      _actionedPlayList.studentplaylist_save = {};
    } else if (actionType == "un-favorite") {
      getStudentPlayList();
      _actionedPlayList.studentplaylist_save = null;
    }
    playListDetails[__actionedPlaylistIndex] = _actionedPlayList;
    setPlayListDetails(playListDetails);
  };

  return (
    <div style={{ padding: "1rem" }}>
      {selectedPlaylist ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#c4c4c4",
              borderRadius: "10px",
              padding: ".5rem",
            }}
          >
            <h1>{selectedPlaylist}</h1>
            <BackIcon
              fontSize="large"
              onClick={() => onClickOfPlaylist(undefined, [])}
              style={{
                cursor: "pointer",
                fill: "white",
                borderRadius: "50%",
                background: "black",
              }}
            />
          </div>
          {selectedFeedId > 0 ? (
            <LectureDetailsContainer
              setLectureDetailsOpen={viewSelectedFeedDetail}
              selectedFeedId={selectedFeedId}
            />
          ) : (
            <LecturesContainer
              onActionCallBack={onActionCallBack}
              lectures={selectedPlayListFeeds}
              lectureItemClickHandler={viewSelectedFeedDetail}
              onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
              blockWidth={selectedFeedId > 0 ? "100%" : "50%"}
              isLoading={loadingPlayList}
            />
          )}
        </>
      ) : (
        <>
          {playListDetails?.length === 0 && (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  padding: "1rem",
                  textAlign: "center",
                  fontSize: "2rem",
                  opacity: "0.3",
                }}
              >
                No playlist available
              </div>
            </div>
          )}
          {playListDetails.map((item) => (
            <div style={{ position: "relative" }}>
              <div
                onClick={() =>
                  onClickOfPlaylist(item.title, item._manipulatedFeedData)
                }
              >
                <div
                  key={item.id}
                  style={{
                    padding: "1rem",
                    backgroundColor: "#c4c4c4",
                    borderRadius: "5px",
                    fontSize: "17px",
                    marginBottom: ".5rem",
                    cursor: "pointer",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{item.title}</span>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "12px",
                  top: "10px",
                  cursor: "pointer",
                }}
              >
                <div>
                  <>
                    {identity.userType !== "teacher" && (
                      <StudentPlayListFavuriteMenu
                        loading={loading}
                        setLoading={setLoading}
                        identity={identity}
                        setToast={setToast}
                        playListId={item.id}
                        teacherId={item.teacher_id}
                        postTypeFavourite={item.studentplaylist_save == null}
                        postTypeUnFavourite={item.studentplaylist_save !== null}
                        onActionCallBackOfPlayList={onActionCallBackOfPlayList}
                      />
                    )}
                  </>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default Playlist;
