import React, { useEffect } from 'react'
import classes from "./Toast.module.css"

function getColor(status) {
    switch (status) {
        case "success":
            return "#4caf50"
        case "error":
            return "#ef5350"
        case "warning":
            return "#ff9800"
        default:
            return "grey"
    }
}

const Toast = ({ data, hideToast }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            hideToast();
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    }, [data]);

    return (
        <div
            className={classes.container}
            style={{
                backgroundColor: getColor(data.status)
            }}
            onClick={hideToast}
        >
            <h3 className={classes.title}>{data.title}</h3>
            <p className={classes.body}>{data.body}</p>
        </div>
    )
}

export default Toast