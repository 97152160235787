import React from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";

// Pages
import NavbarOut from "../components/navbar-out/NavbarOut";
// import Login from "../pages/auth/login/Login";
import Login from "../pages/auth/login/LoginNew";
import SignUp from "../pages/auth/sign-up/SignUp";
import ContactUs from "../pages/contact-us/ContactUs";
import OurMission from "../pages/our-mission/OurMission";
import AdminRegister from "../pages/admin/register/Register";
import PageNotFound from "../pages/page-not-found/PageNotFound";
import Landing from "../pages/landing/Landing";
import UnSubscribeEmail from "../pages/unSubscribeEmail/UnSubscribeEmail";

const OpenRoutes = () => {
  const location = useLocation();

  // List of paths that do not require NavbarOut
  const hideNavbarPaths = ["/un-subscribe/:user_id", "/un-subscribe"];

  // Check if the current path is one where the NavbarOut should be hidden
  const isHideNavbar = hideNavbarPaths.some((path) =>
    matchPath(path, location.pathname)
  );

  // Check if the current path matches any defined routes
  const isPathDefined = [
    "/",
    "/our-mission",
    "/login",
    "/sign-up",
    "/contact-us",
    "/admin-registration/:inviteCode",
    "/un-subscribe/:user_id",
    "/un-subscribe",
  ].some((path) => matchPath(path, location.pathname));

  // Show NavbarOut only if not hiding on specific paths and the path is defined
  const shouldShowNavbar = !isHideNavbar && isPathDefined;

  return (
    <>
      {shouldShowNavbar && <NavbarOut />}
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/our-mission" element={<OurMission />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route
          exact
          path="/admin-registration/:inviteCode"
          element={<AdminRegister />}
        />
        <Route
          exact
          path="/un-subscribe/:user_id"
          element={<UnSubscribeEmail />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default OpenRoutes;
