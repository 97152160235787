import React, { useState, useContext, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import axiosInstance from "../../helpers/axiosInstance";
import { AuthContext } from "../../contexts/AuthContext";
import { UIContext } from "../../contexts/UIContext";
import Toast from "../../components/ui/toast/Toast";
import PlayListingModal from "../../pages/Dialogs/PlayListingModal";
import DeletePostModal from "../../pages/Dialogs/DeletePostModal";
import { useParams } from "react-router-dom";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export default function LecturesFeedMenu(props) {
  const { loading, setLoading } = useContext(UIContext);
  const { identity } = useContext(AuthContext);
  const { id } = useParams();

  const {
    getFeed,
    onActionCallBack,
    postId,
    postTypeSave,
    postTypeFavourite,
    postTypeUnFavourite,
    feedType,
    postTypePlayList,
    onCallbackOfDeleteFeedPost,
    postTypeUnSave,
    feedDetail,
    getFeedMoreDetail,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [playlistModalOpen, setPlaylistModalOpen] = useState(false);
  const [playListDetails, setPlayListDetails] = useState([]);
  const [toast, setToast] = useState(null);
  const open = Boolean(anchorEl);
  const [postDeletOpen, setPostDeletOpen] = useState(false);

  const tosterInfo = {
    true: { status: "success", title: "Success" },
    false: { status: "error", title: "Error" },
  };

  useEffect(() => {
    if (playlistModalOpen) {
      getPlayListDetails(identity.userId);
      handleClose();
    }
    if (postDeletOpen) {
      handleClose();
    }
  }, [playlistModalOpen, postDeletOpen]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPlayListDetails = (teacherId) => {
    axiosInstance
      .get(`/playlist/lists?teacher_id=${teacherId}`)
      .then((res) => {
        setPlayListDetails(res.data.playlists);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const savePostFavorites = (e, actionType, postId, playListId = null) => {
    e.preventDefault();
    handleClose();
    if (loading === false) {
      let actionStatus = null;
      let requestData = {
        type: actionType,
        post_id: postId,
        post_user_id: identity.userId,
        user_type: identity.userType,
      };
      let errorData = null;
      let successInfo = null;
      // setLoading(true);
      let _requestInstance = "";
      if (actionType === "un-favorite") {
        requestData.type = "favorite";
        _requestInstance = axiosInstance.put("/post/un-favorite", requestData);
      } else if (actionType === "un-save") {
        requestData.type = "save";
        _requestInstance = axiosInstance.post("/post/un-save", requestData);
      } else {
        requestData.playlist_id = playListId;
        _requestInstance = axiosInstance.post("/post/save", requestData);
      }
      _requestInstance
        .then((res) => {
          successInfo = res.data;
          actionStatus = 1;
          getFeed("main-feed");
          getFeed("following-feed");
          getFeed("favourite-feed");
        })
        .catch((err) => {
          errorData = err;
          actionStatus = 0;
          console.log(err);
        })
        .finally(() => {
          let isSuccess = "false";
          let successErrorMessage = "Something went wrong, please try again!";
          if (actionStatus === 1) {
            successErrorMessage = successInfo.msg;
            isSuccess = successInfo.state;
            getFeedMoreDetail(feedDetail?.teacher_id);
          }
          setToast({
            status: tosterInfo[isSuccess].status,
            title: tosterInfo[isSuccess].title,
            body: successErrorMessage,
          });
          if (playlistModalOpen) {
            setPlaylistModalOpen(false);
          }
          if (typeof onActionCallBack === "function") {
            onActionCallBack(actionStatus, actionType, {
              successData: successInfo,
              errorData: errorData,
              requestData: requestData,
              feedType: feedType,
            });
          }
        });
    }
  };

  //Set useEffect based on popup open
  useEffect(() => {
    if (playlistModalOpen) {
      handleClose();
      getPlayListDetails(identity.userId);
    }
    if (postDeletOpen) {
      handleClose();
    }
  }, [playlistModalOpen, postDeletOpen]);

  return (
    <div>
      {(postTypeSave ||
        postTypeFavourite ||
        postTypePlayList ||
        postTypeUnFavourite ||
        postTypeUnSave) && (
        <>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ color: "white" }}
          >
            <MoreVertIcon fontSize="medium" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              style: {
                display: "flex",
                flexDirection: "column",
              },
            }}
            PaperProps={{
              sx: {
                width: postTypeUnFavourite
                  ? "240px"
                  : identity.userType === "student"
                  ? "120px"
                  : !postTypePlayList
                  ? "110px"
                  : "170px",
              },
            }}
          >
            {postTypeFavourite && (
              <MenuItem
                onClick={(e) => savePostFavorites(e, "favorite", postId)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "8px 16px",
                  gap: "8px",
                  lineHeight: "20px",
                }}
              >
                <FavoriteBorderIcon
                  fontSize="small"
                  style={{
                    fontSize: "20px",
                    marginLeft: "-2px",
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    marginRight: "42px",
                  }}
                >
                  Favorite
                </span>
              </MenuItem>
            )}
            {postTypeUnFavourite && (
              <MenuItem
                onClick={(e) => savePostFavorites(e, "un-favorite", postId)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  padding: "8px 16px",
                  gap: "8px",
                  lineHeight: "20px",
                }}
              >
                <RemoveCircleOutlineIcon
                  fontSize="small"
                  style={{
                    fontSize: "20px",
                    marginLeft: postTypeUnFavourite ? "-3px" : "1px",
                  }}
                />
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  Remove from favorites
                </span>
              </MenuItem>
            )}
            {postTypePlayList && (
              <>
                <MenuItem
                  onClick={() => setPlaylistModalOpen(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "100%",
                    padding: "8px 16px",
                    gap: "8px",
                    lineHeight: "20px",
                  }}
                >
                  <PlaylistPlayIcon
                    fontSize="small"
                    style={{
                      fontSize: "24px",
                      marginLeft: postTypeUnFavourite ? "-1px" : "1px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      marginRight: postTypeUnFavourite ? "60px" : "",
                    }}
                  >
                    Add to Playlist
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => setPostDeletOpen(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "100%",
                    padding: "8px 16px",
                    gap: "8px",
                    lineHeight: "20px",
                  }}
                >
                  <DeleteOutlineIcon
                    fontSize="small"
                    style={{
                      fontSize: "20px",
                      marginLeft: postTypeUnFavourite ? "-3px" : "1px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      marginRight: postTypeUnFavourite ? "80px" : "19px",
                    }}
                  >
                    Delete Post
                  </span>
                </MenuItem>
              </>
            )}
          </Menu>
        </>
      )}

      <PlayListingModal
        playlistModalOpen={playlistModalOpen}
        setOpen={setPlaylistModalOpen}
        playListDetails={playListDetails}
        handleClose={handleClose}
        getPlayListDetails={getPlayListDetails}
        postId={postId}
        id={id}
        submitFunction={savePostFavorites}
        identity={identity}
      />
      <DeletePostModal
        open={postDeletOpen}
        setOpen={setPostDeletOpen}
        postDeletOpen={postDeletOpen}
        loading={loading}
        setLoading={setLoading}
        setToast={setToast}
        postId={postId}
        onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
}
