import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// Pages
import NavbarIn from "../components/navbar-in/NavbarIn";
import CreatePost from "../pages/create-post/CreatePost";
import Dashboard from "../pages/dashboard/Dashboard";
import TeacherProfile from "../pages/teacher-profile/TeacherProfile";
import PageNotFound from "../pages/page-not-found/PageNotFound";
import SearchContainer from "../containers/SearchContainer";

const TeacherRoutes = (props) => {
  const location = useLocation();

  // List of paths where the NavbarIn should be shown
  const pathsToShowNavbar = [
    "/",
    "/dashboard",
    "/dashboard/:slug",
    "/teacher-profile",
    "/teacher-profile/:id/:tabName",
    "/teacher-profile/:id/:tabName/:slug",
    "/create-post",
    "/search/:queryString"
  ];

  // Determine if the current route matches any of the defined paths
  const showNavbar = pathsToShowNavbar.some((path) => {
    const match = new RegExp(`^${path.replace(/:[^\s/]+/g, "([^/]+)")}$`);
    return match.test(location.pathname);
  });

  return (
    <>
      {showNavbar && <NavbarIn path={location.pathname.split("/")[1]} {...props} />}
      <Routes>
        <Route exact path="/" element={<Navigate to={"/dashboard"} />} />
        <Route exact path="/search/:queryString" element={<SearchContainer />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/dashboard/:slug" element={<Dashboard />} />
        <Route exact path="/teacher-profile" element={<TeacherProfile />} />
        <Route exact path="/teacher-profile/:id/:tabName" element={<TeacherProfile />} />
        <Route exact path="/teacher-profile/:id/:tabName/:slug" element={<TeacherProfile />} />
        <Route exact path="/create-post" element={<CreatePost />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default TeacherRoutes;
