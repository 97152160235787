import React, { useState, createContext, useEffect } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [identity, setIdentity] = useState({
    isLoggedIn: false,
    userType: "",
    username: "",
    userId: "",
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userIdentity"));
    if (user) {
      setIdentity({
        isLoggedIn: true,
        userType: user.userType,
        username: user.username,
        userId: user.userId,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ identity, setIdentity }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
