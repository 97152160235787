import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import NavbarAdminContainer from "../containers/NavbarAdminContainer";

// Pages
import AdminDashboard from "../pages/admin/dashboard/Dashboard";
import PageNotFound from "../pages/page-not-found/PageNotFound";

const AdminRoutes = () => {
  return (
    <>
      <NavbarAdminContainer />
      <Routes>
        <Route exact path="/" element={<AdminDashboard />} />
        <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default AdminRoutes;
