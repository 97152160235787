import React, { useState } from "react";
import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const customStyles = {
  inputDiv: {
    marginBottom: "1rem",
  },
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
  },
  btnDiv: {
    textAlign: "center",
  },
  iconButton: {
    padding: 0,
    minWidth: "unset",
    backgroundColor: "transparent", // Ensures the button background is transparent
    "&:hover": {
      backgroundColor: "transparent", // Prevents hover background
    },
  },
};

export default function LoginModal({
  open,
  setOpen,
  username,
  password,
  setUsername,
  setPassword,
  handleSubmit,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle id="alert-dialog-title">Login</DialogTitle>
          <CloseIcon
            onClick={() => setOpen(false)}
            fontSize="large"
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <DialogContent>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                id="username"
                name="username"
                type="text"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                id="password"
                name="password"
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={handleClickShowPassword}
                        style={customStyles.iconButton}
                        disableRipple
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={customStyles.btnDiv}>
              <Button
                variant="contained"
                style={customStyles.submitBtn}
                type="submit"
              >
                LOG IN
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
