import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AuthContextProvider from "./contexts/AuthContext";
import UIContextProvider from "./contexts/UIContext";
import "./index.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthContextProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLINET_ID}>
        <UIContextProvider>
          <App />
        </UIContextProvider>
      </GoogleOAuthProvider>
    </AuthContextProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
