import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const customStyles = {
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    marginTop: "17px",
  },
  btnDiv: {
    textAlign: "center",
  },
};

export default function EmailDomainModal({
  open,
  setOpen,
  loading,
  addAuthorizeDomain,
  domain,
  setDomain,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle id="alert-dialog-title">
            Add a authorize domain
          </DialogTitle>
          <CloseIcon
            onClick={() => setOpen(false)}
            fontSize="medium"
            style={{
              marginTop: "18px",
              marginRight: "1rem",
              marginLeft: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            label="Enter Domain"
            name="Enter Domain"
            title="Enter Domain (eg.google.com)"
            onChange={(e) => setDomain(e.target.value)}
          />
          <div style={customStyles.btnDiv}>
            <Button
              style={customStyles.submitBtn}
              onClick={(e) => addAuthorizeDomain(e, domain)}
              disabled={loading || domain.trim() == ""}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
