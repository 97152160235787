import classes from "./TeacherList.module.css";
import CF from "../../../utils/CommonFunction";

function TeacherListInput({
  teacherList = [],
  teacherEmails,
  setTeacherList,
  saveTeacherList,
  editable,
  setToast,
  newTeacherList,
  setTewTeacherList,
  details,
}) {
  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    if (!CF.isValidEmail(value)) {
      setToast(CF.alert.error("Please enter valid email address."));
    } else if (teacherList.includes(value)) {
      setToast(
        CF.alert.error(
          "You cannot enter same an email again. Please, enter diffrent email."
        )
      );
    } else {
      const email = value;
      const arrySplit = email.split("@");
      if (details.domain.indexOf(arrySplit[1]) === -1) {
        let errorMessage = `Allowed, only emails with domain ${JSON.parse(
          details.domain
        )
          .join(", ")
          .toUpperCase()}`;
        setToast(CF.alert.error(errorMessage));
      } else {
        setTeacherList([...teacherList, value]);
        setTewTeacherList([...newTeacherList, value]);
        e.target.value = "";
      }
    }
  }

  function onSave(e) {
    if (teacherList.length === 0) {
      setToast(CF.alert.error("Please, enter an email."));
      return false;
    }
    saveTeacherList();
  }

  function handleOnBlur(e) {
    e.target.value = "";
  }

  function removeTag(index) {
    // if (!editable) return;
    setTeacherList(teacherList.filter((el, i) => i !== index));
  }

  return (
    <>
      <h2 className={classes.headerText}>Teacher List</h2>
      <div className={classes.inputContainer}>
        {teacherList.map((tag, index) => (
          <div className={classes.item} key={index}>
            <span className="text">{tag}</span>
            {/* {editable && ( */}
            <span className={classes.close} onClick={() => removeTag(index)}>
              &times;
            </span>
            {/* )} */}
          </div>
        ))}
        <input
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          type="text"
          className={classes.input}
          // placeholder={editable && "Type an email"}
          placeholder={"Type an email"}
          // disabled={!editable}
          title={`Allowed, only emails with domain ${details.domain.toUpperCase()}`}
        />
      </div>
      {/* {editable && ( */}
      <>
        <button
          className={classes.saveBtn}
          onClick={onSave}
          disabled={teacherList.length === 0 ? "disabled" : ""}
        >
          Save
        </button>
        <button
          className={classes.resetBtn}
          onClick={() => setTeacherList(teacherEmails)}
        >
          Reset
        </button>
      </>
      {/* )} */}
    </>
  );
}

export default TeacherListInput;
