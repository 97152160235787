import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LecturesContainer from "../../containers/LecturesContainer";
import Playlist from "./Playlist";
import TeacherControls from "./TeacherControls";
import TeacherDetails from "./TeacherDetails";
import classes from "./TeacherProfile.module.css";
import ViewRatings from "./ViewRatings";
import axiosInstance from "../../helpers/axiosInstance";
import { AuthContext } from "../../contexts/AuthContext";
import { UIContext } from "../../contexts/UIContext";
import CF from "../../utils/CommonFunction";
import { Grid } from "@material-ui/core";
import LectureDetailsContainer from "../../containers/LectureDetailsContainer";

const TeacherProfile = () => {
  const { id, tabName, slug } = useParams();
  const [lectureDetailsOpen, setLectureDetailsOpen] = useState(false);
  const [activeControl, setActiveControl] = useState("lectures");
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const [activeTab, setActiveTab] = useState("your-posts");
  const [teacherFeed, setTeacherFeed] = useState([]);
  const { identity } = useContext(AuthContext);
  const { setLoading } = useContext(UIContext);
  const [selectedFeedId, setSelectedFeedId] = useState(0);
  const [ratingInfo, setRatingInfo] = useState({});
  const navigate = useNavigate();
  const [isPlayListSubmited, setIsPlayListSubmited] = useState(false);
  const [loadingYourFeed, setLoadingLoadingYourFeed] = useState(false);

  const viewSelectedFeedDetail = (feedId) => {
    setSelectedFeedId(feedId);
  };

  useEffect(() => {
    //change here
    getTeacherFeedDeatils(id, activeTab);
    setActiveControl(tabName);
  }, []);

  const onChangeActiveTabs = (tabName) => {
    if (activeControl === "playlist") {
      setSelectedPlaylist(null);
    }

    if (slug) {
      navigate({
        pathname: "/teacher-profile/" + id + "/" + tabName + "/" + slug,
      });
    } else {
      navigate({
        pathname: "/teacher-profile/" + id + "/" + tabName,
      });
    }

    setActiveControl(tabName);
  };

  const selectActiveTab = (activeTabType) => {
    setActiveTab(activeTabType);
    getTeacherFeedDeatils(identity.userId, activeTabType);
  };

  const getTeacherFeedDeatils = (teacherId, activeTabType) => {
    let apiEndPoint;
    if (activeTabType === "your-posts") {
      //Your Post API
      setLoadingLoadingYourFeed(true);
      apiEndPoint = "post/teacher-feed?teacherId=" + teacherId;
    } else {
      //Save Post API
      setLoadingLoadingYourFeed(true);
      apiEndPoint = "post/save?type=favorite&user_type=" + identity.userType;
    }
    // setLoading(true);
    axiosInstance
      .get(apiEndPoint)
      .then((res) => {
        if (activeTabType === "your-posts") {
          setTeacherFeed(CF.assignRandomColorCodeInArray(res.data.feed));
        } else {
          setTeacherFeed(CF.assignRandomColorCodeInArray(res.data.feeds));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        if (activeTabType === "your-posts") {
          setLoadingLoadingYourFeed(false);
        } else {
          setLoadingLoadingYourFeed(false);
        }
      });
  };

  const onActionCallBack = (actionStatus, actionType, additionalValue) => {
    const feedId = additionalValue.requestData.post_id;
    if (actionStatus === 1) {
      let feedData = teacherFeed;
      let _mainFeed = feedData.map(function (singleFeed) {
        if (singleFeed.id === feedId) {
          if (actionType === "favorite") {
            singleFeed.favourite_save = {};
          } else if (actionType === "favorite") {
            singleFeed.favourite_save = {};
          } else if (actionType === "un-favorite") {
            singleFeed.favourite_save = null;
            if (activeTab == "favorite") {
              getTeacherFeedDeatils(identity.userId);
            }
          }
        }
        return singleFeed;
      });
      setTeacherFeed(_mainFeed);
    }
  };

  const onCallbackOfDeleteFeedPost = () => {
    getTeacherFeedDeatils(id, activeTab);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item lg={5} md={12} sm={12}>
        <div className={classes.left}>
          <TeacherDetails
            teacherId={id}
            setRatingInfo={setRatingInfo}
            setIsPlayListSubmited={setIsPlayListSubmited}
          />
        </div>
      </Grid>
      <Grid item lg={1} md={12} sm={12}>
        <div className={classes.center}>
          <TeacherControls
            activeControl={activeControl}
            onChangeActiveTabs={onChangeActiveTabs}
            selectedPlaylist={selectedPlaylist}
          />
        </div>
      </Grid>
      <Grid item lg={6} md={12} sm={12}>
        <div className={classes.right}>
          {activeControl == "lectures" && identity.userId == id && (
            <div
              className={classes.tabs}
              style={{ border: "1px solid lightgrey", borderRadius: 12 }}
            >
              <div
                className={classes.tabLeft}
                onClick={(e) => selectActiveTab("your-posts")}
                style={{
                  backgroundColor: activeTab === "your-posts" && "lightblue",
                }}
              >
                Your Posts
              </div>
              <div
                className={classes.tabRight}
                onClick={(e) => selectActiveTab("favorite")}
                style={{
                  backgroundColor: activeTab === "favorite" && "lightblue",
                }}
              >
                Favorites
              </div>
            </div>
          )}
          <div className={`${classes.right} ${classes.scrollContainer}`}>
            {activeControl === "lectures" && (
              <div>
                {selectedFeedId > 0 ? (
                  <div
                    className={`${classes.scrollViewDetails} ${classes.scrollableContent}`}
                  >
                    <LectureDetailsContainer
                      setLectureDetailsOpen={viewSelectedFeedDetail}
                      selectedFeedId={selectedFeedId}
                    />
                  </div>
                ) : (
                  <div
                    className={`${classes.scrollViewLectures} ${classes.scrollableContent}`}
                  >
                    <LecturesContainer
                      activeTabType={activeTab}
                      lectures={teacherFeed}
                      useForType={activeTab}
                      lectureItemClickHandler={viewSelectedFeedDetail}
                      onActionCallBack={onActionCallBack}
                      onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
                      blockWidth={selectedFeedId > 0 ? "100%" : "50%"}
                      isLoading={loadingYourFeed}
                    />
                  </div>
                )}
              </div>
            )}
            {activeControl === "playlist" && (
              <div
                className={`${classes.scrollView} ${classes.scrollableContent}`}
              >
                <Playlist
                  teacherId={id}
                  selectedPlaylist={selectedPlaylist}
                  isPlayListSubmited={isPlayListSubmited}
                  setSelectedPlaylist={setSelectedPlaylist}
                  onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
                />
              </div>
            )}
            {activeControl === "view-ratings" && (
              <ViewRatings teacherId={id} ratingInfo={ratingInfo} />
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default TeacherProfile;
