import React from "react";
// import StarIcon from "@material-ui/icons/Star";
import classes from "./LectureRatings.module.css";

const LectureRatings = ({ viewCount, size, color }) => {
  const customStyles = {
    fontSize: size === "small" ? ".8em" : "1em",
    color: color ? color : "grey",
    marginRight: "11px",
  };
  return (
    <div className={classes.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <StarIcon
          className={classes.star}
          fontSize="small"
          style={{ color: "#FCD925", marginRight: "9px" }}
        />
        <label style={customStyles} className={classes.ratingCount}>
          {rating} &nbsp; {ratingCount} Ratings
        </label> */}
      </div>
      <label style={customStyles} className={classes.viewRatingText}>
        {viewCount} Views
      </label>
    </div>
  );
};

export default LectureRatings;
