import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";

const customStyles = {
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    marginTop: "17px",
  },
  inputDiv: {
    marginBottom: "1rem",
  },
  btnDiv: {
    textAlign: "center",
  },
};

export default function EditInstitutionDetailsModal({
  open,
  setOpen,
  handleChangeInstitutionDetails,
  updateInstitutionDetails,
  handleSubmit,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Institution Details
        </DialogTitle>
        <DialogContent>
          <form>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                label="Institution name"
                name="name"
                value={updateInstitutionDetails.name}
                onChange={handleChangeInstitutionDetails}
                required
              />
            </div>
            <div style={customStyles.inputDiv}>
              <TextField
                variant="outlined"
                fullWidth
                label="Address"
                name="address"
                value={updateInstitutionDetails.address}
                onChange={handleChangeInstitutionDetails}
                required
              />
            </div>
            <div style={customStyles.btnDiv}>
              <Button
                variant="contained"
                style={customStyles.submitBtn}
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
