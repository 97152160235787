import React, { useState } from "react";
import logo from "../../assets/images/schooled_logo.png";
import classes from "./NavbarOut.module.css";
import { NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const NavList = ({ redirectNewExplorePage, redirectNewOurMissionPage }) => (
  <>
    <li className={classes.navItem}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive
            ? `${classes.navLink} ${classes.active}`
            : `${classes.navLink}`
        }
      >
        Home
      </NavLink>
    </li>
    <li className={classes.navItem}>
      <NavLink
        to="/our-mission"
        className={({ isActive }) =>
          isActive
            ? `${classes.navLink} ${classes.active}`
            : `${classes.navLink}`
        }
      >
        Our Mission
      </NavLink>
    </li>
    <li className={classes.navItem}>
      <NavLink
        to="/contact-us"
        className={({ isActive }) =>
          isActive
            ? `${classes.navLink} ${classes.active}`
            : `${classes.navLink}`
        }
      >
        Contact Us
      </NavLink>
    </li>

    <li className={classes.navItem}>
      <NavLink to="/login" className={`${classes.navLink} ${classes.loginBtn}`}>
        {" "}
        LOG IN
      </NavLink>
    </li>
    {/* <li className={classes.navItem}>
      <span
        className={`${classes.navLink} ${classes.loginBtn}`}
        onClick={redirectNewExplorePage}
      >
        Explore
      </span>
    </li> */}
  </>
);

const NavbarOut = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);

  const redirectNewExplorePage = () => {
    window.open("https://schooled.info/");
  };

  const redirectNewOurMissionPage = () => {
    window.open("https://schooled.info/our-mission");
  };

  return (
    <>
      <header className={classes.navbar}>
        <NavLink to="/">
          <img src={logo} className={classes.logo} alt="Schooled" />
        </NavLink>
        <div className={classes.menuBtn}>
          <IconButton onClick={toggleMenu}>
            {menuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>
        <nav className={classes.navDesktop}>
          <ul className={classes.navList}>
            <NavList
              redirectNewExplorePage={redirectNewExplorePage}
              redirectNewOurMissionPage={redirectNewOurMissionPage}
            />
          </ul>
        </nav>
      </header>
      {menuOpen && (
        <nav className={classes.navMobile} onClick={closeMenu}>
          <ul className={classes.navList}>
            <NavList
              redirectNewExplorePage={redirectNewExplorePage}
              redirectNewOurMissionPage={redirectNewOurMissionPage}
            />
          </ul>
        </nav>
      )}
    </>
  );
};

export default NavbarOut;
