import React from "react";
import classes from "./ContactUs.module.css";
import emailLogo from "../../assets/images/email-logo.png";
import searchLogo from "../../assets/images/search-icon.png";
import contactUsLogo from "../../assets/images/contact-us.png";
import emailUsContent from "../../assets/images/email-us-content.png";
import emergencyContactContent from "../../assets/images/emergency-contact-content.png";

const ContactUs = () => {
  return (
    <div className={classes.main}>
      <img
        src={contactUsLogo}
        className={classes.logo}
        alt="our-mission"
        loading="lazy"
      />
      <div className={classes.emailcontentDiv}>
        <img
          src={emailUsContent}
          className={classes.emailUsContentImg}
          alt="email-content"
        />
        <img src={emailLogo} alt="email-icon" />
      </div>
      <div className={classes.emergencycontentDiv}>
        <img
          src={emergencyContactContent}
          className={classes.emergencyContentImg}
          alt="emergency-content"
          loading="lazy"
        />
        <img src={searchLogo} alt="search-icon" />
      </div>
    </div>
  );
};

export default ContactUs;
