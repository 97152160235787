import React, { useContext, useEffect, useState } from "react";
import classes from "./UnSubscribeEmail.module.css";
import logo from "../../assets/images/schooled_logo.png";
import { useNavigate } from "react-router-dom";
import { UIContext } from "../../contexts/UIContext";
import Toast from "../../components/ui/toast/Toast";
import axiosInstance from "../../helpers/axiosInstance";
import CF from "../../utils/CommonFunction";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

const UnSubscribeEmail = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(UIContext);
  const [toast, setToast] = useState();
  const { user_id } = useParams();
  const [unsubscribe, setUnsubscribe] = useState("");
  const { identity } = useContext(AuthContext);
  const [loadingData, setLoadingData] = useState("");

  useEffect(() => {
    getUnscribeData(user_id);
  }, []);

  const getUnscribeData = (user_id) => {
    setLoading(true);
    axiosInstance
      .get("/admin/un-subscribe?user_id=" + user_id)
      .then((res) => {
        if (res.data.msg == "Unsubscribe successfully") {
          setToast(CF.alert.success(res.data.msg));
          setTimeout(function () {
            navigate(`/`);
          }, 2000);
          setUnsubscribe("Success");
          setLoadingData("Success");
          setLoading(false);
        } else {
          setToast(CF.alert.success(res.data.msg));
          setTimeout(function () {
            navigate(`/`);
          }, 2000);
          setUnsubscribe("Subscribe");
          setLoadingData("Success");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    //use login or not
    if (!identity.isLoggedIn) {
      if (JSON.parse(localStorage.getItem("userIdentity"))) {
        setToast(CF.alert.success("Your current account logout successfully."));
      }
      localStorage.removeItem("userIdentity");
    }
  }, []);

  return (
    <>
      {loadingData === "Success" && (
        <div className={classes.container}>
          <div className={classes.center}>
            <img
              src={logo}
              className={classes.logo}
              alt="Schooled"
              onClick={(e) => navigate(`/`)}
            />
          </div>
          <h1 className={classes.mainText}>Thank You</h1>
          {unsubscribe === "Success" && (
            <h3 className={classes.description}>
              Your <span>unsubscribe</span> request has been successful.
            </h3>
          )}
          {unsubscribe === "Subscribe" && (
            <h3 className={classes.description}>
              Your <span>subscribe</span> request has been successful.
            </h3>
          )}
        </div>
      )}
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </>
  );
};

export default UnSubscribeEmail;
