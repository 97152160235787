import React from "react";
import BackArrowIcon from "@material-ui/icons/ArrowBack";
import { IconButton, Button, Menu } from "@material-ui/core";
import CF from "../../../utils/CommonFunction";
import classes from "./TeacherDetails.module.css";
import Avatar from "@material-ui/core/Avatar";
// import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../../../helpers/axiosInstance";

// const ITEM_HEIGHT = 30;

const StudentDetails = ({
  setSelectedStudentId,
  selectedStudent,
  setLoading,
  setToast,
  onCallbackOfUserTypeChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleClickChangeUserRole = () => {
    setLoading(true);
    let requestData = {
      userType: "teacher",
      user_id: selectedStudent.userId,
      institutionId: selectedStudent.institution_id,
    };
    axiosInstance
      .post("/super/change-role", requestData)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          onCallbackOfUserTypeChange();
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        console.error(err);
        setToast(
          CF.alert.error("Something went wrong, while givining a rating")
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div>
        <div>
          <IconButton
            aria-label="back button"
            onClick={() => setSelectedStudentId(null)}
          >
            <BackArrowIcon />
          </IconButton>
          <label>Student Details</label>
        </div>
        <div className={classes.details}>
          <div className={classes.teacherProfile}>
            <Avatar
              src={selectedStudent?.profileImage}
              loading="lazy"
              style={{
                height: "7rem",
                width: "7rem",
                marginTop: "-10px",
              }}
              className={classes.teacherImg}
            >
              {CF.capitalizeFirstLetter(
                selectedStudent?.firstName?.substring(0, 1)
              )}
            </Avatar>
            <div>
              <h3 className={classes.nameText}>
                {CF.capitalizeFirstLetter(selectedStudent?.firstName)} &nbsp;
                {selectedStudent?.lastName}
              </h3>
              <p
                style={{
                  marginTop: "10px",
                }}
                className={classes.institutionName}
              >
                {selectedStudent?.institution?.name}
              </p>
              {selectedStudent?.bio && (
                <p className={classes.bigText}>{selectedStudent?.bio}</p>
              )}
            </div>
          </div>
          <div>
            <Button
              variant="outlined"
              style={{
                color: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
                marginRight: "1rem",
                marginBottom: "1rem",
              }}
              onClick={(e) => handleClickChangeUserRole(e)}
            >
              Change role
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
