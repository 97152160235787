import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";

// Routes
import AdminRoutes from "./routes/admin";
import OpenRoutes from "./routes/open";
import StudentRoutes from "./routes/student";
import SuperAdminRoutes from "./routes/super-admin";
import TeacherRoutes from "./routes/teacher";

function getUserRoute(userType) {
  switch (userType) {
    case "super":
      return <SuperAdminRoutes />;
    case "admin":
      return <AdminRoutes />;
    case "teacher":
      return <TeacherRoutes />;
    case "student":
      return <StudentRoutes />;
    default:
      return <OpenRoutes />;
  }
}

function App() {
  let navigate = useNavigate();
  const location = useLocation();
  const token = JSON.parse(localStorage.getItem("userIdentity"));
  let protectedRoute = ["login"];
  let path = location.pathname.split("/")[1];

  useEffect(() => {
    if (process.env.REACT_APP_HOST_ENVIRONMENT === "production") {
      var script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=G-8BSH8RWP0M"
      );
      document.getElementsByTagName("head")[0].appendChild(script);
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-8BSH8RWP0M");
    }

    window.addEventListener("storage", () => {
      if (localStorage.getItem("userIdentity") === null) {
        window.location.href = "/login";
      }
    });
  }, []);

  useEffect(() => {
    if (token?.accessToken && token?.userType == "admin") {
      navigate("/admin-dashboard");
    }
    if (token?.accessToken && token?.userType == "super") {
      if (protectedRoute.includes(path)) {
        navigate("/super-admin-dashboard");
      }
    }
    if (token?.accessToken && token?.userType == "teacher") {
      if (protectedRoute.includes(path)) {
        navigate("/dashboard");
      }
    }
    if (token?.accessToken && token?.userType == "student") {
      if (protectedRoute.includes(path)) {
        navigate("/dashboard");
      }
    }
  }, [location.pathname]);

  const { identity } = useContext(AuthContext);
  if (!identity.isLoggedIn) {
    return <OpenRoutes />;
  }

  return getUserRoute(identity.userType);
}

export default App;
