import { Grid, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../../components/ui/toast/Toast";
import { UIContext } from "../../../contexts/UIContext";
import axiosInstance from "../../../helpers/axiosInstance";
import classes from "./Dashboard.module.css";
import CF from "../../../utils/CommonFunction";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import EmailDomainModal from "../../Dialogs/EmailDomainModal";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import StudentDetails from "../../admin/dashboard/StudentDetails";

const styles = {
  input: {
    backgroundColor: "#f2f2f2",
    padding: "10px 5px",
    width: "16rem",
    border: "none",
    marginBottom: "5px",
    marginLeft: "0px",
  },
};

const Dashboard = () => {
  const [institutions, setInstitutions] = useState([]);
  const [email, setEmail] = useState("");
  const { loading, setLoading } = useContext(UIContext);
  const [toast, setToast] = useState();
  const [emailDomainModalOpen, setEmailDomainModalOpen] = useState(false);
  const [domainSearchTerm, setDomainSearchTerm] = useState("");
  const [authorizedDomains, setAuthorizedDomains] = useState([]);
  const [domain, setDomain] = useState("");
  const [studentDetail, setStudentDetail] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState();
  const [selectedStudent, setSelectedStudent] = useState({});
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const viewSelectedStudentDetail = (studentId, student) => {
    setSelectedStudentId(studentId);
    setSelectedStudent(student);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getInstitutions();
    getStudentDetails();
  }, []);

  const getStudentDetails = () => {
    setLoading(true);
    axiosInstance
      .get("/student/lists")
      .then((res) => {
        setStudentDetail(res.data.students);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getInstitutions = () => {
    setLoading(true);
    axiosInstance
      .get("/super/institutions")
      .then((res) => {
        const institutions = res.data.institutions;
        setInstitutions(institutions);
        let domains = institutions.map((single) => {
          return single.domain;
        });
        setAuthorizedDomains(domains);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Validation for sent invite email
  const validateInviteEmail = () => {
    let message = [];
    if (email === "") {
      message.push("Please enter email.");
    }
    if (!CF.isValidEmail(email)) {
      message.push("Please enter valid email format.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  const inviteAdmin = (e) => {
    const isValid = validateInviteEmail();
    const value = e.target.value;
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/super/invite", { email })
        .then((res) => {
          setEmail("");
          setToast(CF.alert.success(res.data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setEmail("");
          setLoading(false);
          if (err.response && err.response.data) {
            setToast({
              status: "error",
              title: "Invite Not Sent",
              body: err.response.data.msg,
            });
          }
        });
    }
  };

  //Validation for email domain
  const validateAuthorisedDomain = (domain) => {
    let message = "";
    if (domain === "") {
      message = "Please enter authorize domain.";
    } else if (!CF.isValidEmailDomain(domain)) {
      message = "Please enter valid authorize domain format.";
    } else {
      const domains = authorizedDomains.map(item => JSON.parse(item)).flat()
      if(domains.includes(domain)) {
        message =
            "This domain is already added. Please enter different domain.";
      }
    }
    if (message !== "") {
      setToast({
        status: "error",
        title: "Error",
        body: message,
      });
      return false;
    }
    return true;
  };

  //Authorize a Domain
  const addAuthorizeDomain = (e, domain) => {
    const isValid = validateAuthorisedDomain(domain);
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/super/domain-invite", { domain: JSON.stringify([domain]) })
        .then((res) => {
          if (res.data.state === true) {
            setToast(CF.alert.success(res.data.msg));
            setEmailDomainModalOpen(false);
            getInstitutions();
            setDomain("");
          } else {
            setToast(CF.alert.error(res.data.msg));
          }
        })
        .catch((err) => {
          setToast(
            CF.alert.error("Something went wrong, while add new domain")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCallbackOfUserTypeChange = () => {
    setSelectedStudentId("");
    getStudentDetails();
  };

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch {
      return [str];
    }
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={isSmall ? 0 : 5}
        className={classes.containerGrid}
      >
        <Grid item lg={3} sm={12} className={classes.gridBoder}>
          <h3 className={classes.headerText}>Institutions</h3>
          {institutions?.length === 0 && (
            <p style={{ opacity: "0.3" }}>No institutions found</p>
          )}
          <ul className={classes.institutionList}>
            {institutions.map((institute) => (
              <>
                {institute.name && (
                  <li
                    className={classes.institutionItem}
                    key={institute.id}
                    onClick={() => navigate(`/institution/${institute.id}`)}
                  >
                    {institute.name}
                  </li>
                )}
              </>
            ))}
          </ul>
        </Grid>
        <Grid item lg={3} sm={12} className={classes.gridStudentListBoder}>
          <h3 className={classes.headerText}>Students</h3>
          {studentDetail?.length === 0 && (
            <p style={{ opacity: "0.3" }}>No student details found</p>
          )}
          <ul className={classes.institutionList}>
            {studentDetail.map((student) => (
              <>
                <li
                  className={classes.studentItem}
                  key={student.id}
                  onClick={() =>
                    viewSelectedStudentDetail(student.userId, student)
                  }
                >
                  <Avatar
                    src={student?.profileImage}
                    loading="lazy"
                    className={classes.profileImage}
                  >
                    {CF.capitalizeFirstLetter(
                      student.firstName?.substring(0, 1)
                    )}
                  </Avatar>
                  <span>
                    {CF.capitalizeFirstLetter(student.firstName)}
                    &nbsp;
                    {student.lastName}
                    <p
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      {student?.institution?.name}
                    </p>
                  </span>
                </li>
              </>
            ))}
          </ul>
        </Grid>
        {selectedStudentId ? (
          <div style={{ marginTop: "3rem" }}>
            <StudentDetails
              setSelectedStudentId={setSelectedStudentId}
              selectedStudent={selectedStudent}
              institutions={institutions}
              setLoading={setLoading}
              setToast={setToast}
              onCallbackOfUserTypeChange={onCallbackOfUserTypeChange}
            />
          </div>
        ) : (
          <>
            <Grid item lg={3} sm={12} className={classes.gridBoder}>
              <>
                <h3 className={classes.headerText}>Invite Admin</h3>
                <div style={{ marginBottom: "1rem" }}>
                  <TextField
                    variant="outlined"
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  className={classes.submitBtn}
                  onClick={inviteAdmin}
                  disabled={email.length === 0 ? "disabled" : ""}
                >
                  Invite
                </button>
                <div style={{ display: "flex", marginTop: "2rem" }}>
                  <h3
                    className={classes.headerText}
                    style={{ marginRight: "1rem" }}
                  >
                    Authorized Domain
                  </h3>
                  <Tooltip title="Add a new authorize domain">
                    <AddCircleOutlineOutlinedIcon
                      className={classes.addBtn}
                      onClick={() => setEmailDomainModalOpen(true)}
                    />
                  </Tooltip>
                </div>
                <input
                  style={styles.input}
                  type="text"
                  placeholder="Search Domain"
                  onKeyUp={(e) => setDomainSearchTerm(e.target.value)}
                />
                <ul className={classes.institutionList}>
                  {authorizedDomains
                    .filter((singleDomain) => {
                      if (domainSearchTerm === "") {
                        return singleDomain;
                      } else if (
                        `@${singleDomain}`
                          .toLowerCase()
                          .includes(domainSearchTerm.toLowerCase())
                      ) {
                        return singleDomain;
                      }
                    })
                    .map((singleDomain, index) =>
                      isStringified(singleDomain).map((_domain, index) => (
                        <li
                          key={index}
                          style={{
                            overflow: "hidden",
                            position: "relative",
                            margin: "0 5px 0 5px",
                            textDecoration: "none",
                            textOverflow: "ellipsis",
                          }}
                        >
                          @{_domain}
                        </li>
                      ))
                    )}
                </ul>
              </>
            </Grid>
            <Grid item lg={3} sm={12}>
              <h3 className={classes.headerText}>Website Status</h3>
              <div className={classes.stats}>
                <div className={classes.stat}>
                  <label className={classes.statValue}>120</label>
                  <label className={classes.statLabel}>Current Users</label>
                </div>
                <div className={classes.stat}>
                  <label className={classes.statValue}>102</label>
                  <label className={classes.statLabel}>Current Traffic</label>
                </div>
                <div className={classes.stat}>
                  <label className={`${classes.statValue} ${classes.live}`}>
                    Live
                  </label>
                  <label className={classes.statLabel}>Status</label>
                </div>
                <div className={classes.stat}>
                  <label className={classes.statValue}>11:20:17</label>
                  <label className={classes.statLabel}>Time</label>
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      <EmailDomainModal
        open={emailDomainModalOpen}
        setOpen={setEmailDomainModalOpen}
        loading={loading}
        addAuthorizeDomain={addAuthorizeDomain}
        domain={domain}
        setDomain={setDomain}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default Dashboard;
