import React from "react";
import classes from "./Lectures.module.css";
import CF from "../../utils/CommonFunction";
import Skeleton from "@mui/material/Skeleton";
import { Avatar, Grid } from "@material-ui/core";
import LecturesFeedMenu from "./LecturesFeedMenu";
import LectureRatings from "../ui/lecture-ratings/LectureRatings";

function SkeletonLectures(_width) {
  return (
    <Grid container item spacing={0} direction="column">
      <div>
        <Skeleton
          animation="wave"
          height={380}
          className={classes.avatarLoader}
        />
      </div>
    </Grid>
  );
}

const Lectures = ({
  getFeed,
  lectures,
  identity,
  navigate,
  feedType,
  isLoading,
  blockWidth,
  handleClick,
  onActionCallBack,
  onCallbackOfDeleteFeedPost,
}) => {
  return (
    <div className={classes.container}>
      {Array.from(new Array(isLoading ? 6 : 0)).map((a, i) => (
        <div
          item
          key={i}
          style={{
            width: blockWidth,
            padding: "0 10px 11px",
          }}
        >
          <SkeletonLectures key={i} />
        </div>
      ))}
      {!isLoading && (
        <>
          {lectures?.length === 0 && (
            <div className={classes.noDataFoundMainDiv}>
              <div className={classes.nodataText}>Nothing here</div>
            </div>
          )}
          {lectures.map((lecture) => (
            <div
              className={classes.lectureThumbnailItem}
              style={{
                width: blockWidth,
              }}
            >
              <div
                className={classes.imageMainDiv}
                onClick={(e) => {
                  handleClick(lecture.id, lecture.teacher_id, lecture.slug);
                }}
              >
                <img
                  alt=""
                  src={`https://img.youtube.com/vi/${
                    lecture?.video_id?.includes("?")
                      ? lecture?.video_id?.split("?")[0]
                      : lecture?.video_id
                  }/hqdefault.jpg`}
                  className={classes.thumbnailImage}
                />
              </div>
              <div
                className={classes.centeText}
                onClick={(e) =>
                  handleClick(lecture.id, lecture.teacher_id, lecture.slug)
                }
              >
                teacher’s thumbnail here
              </div>
              <div className={classes.bottomTab}>
                <div className={classes.bottomDiv}>
                  <Avatar
                    src={lecture?.teacher?.profileImage}
                    loading="lazy"
                    className={classes.profileImage}
                    onClick={() =>
                      navigate(
                        "/teacher-profile/" + lecture?.teacher_id + "/lectures"
                      )
                    }
                  ></Avatar>
                  <div
                    className={classes.ThubCaptionText}
                    onClick={() => {
                      handleClick(lecture.id, lecture.teacher_id, lecture.slug);
                    }}
                  >
                    <div style={{ marginTop: "4px" }}>
                      <h3
                        onClick={(e) =>
                          handleClick(
                            lecture?.id,
                            lecture?.teacher_id,
                            lecture?.slug
                          )
                        }
                        title={lecture?.title}
                      >
                        {lecture?.title}
                      </h3>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <h3
                        className={classes.nameText}
                        title={lecture?.teacher?.firstName}
                      >
                        {lecture?.teacher?.teacher_title
                          ? lecture?.teacher?.teacher_title
                          : "Prof"}
                        .{" "}
                        {CF.capitalizeFirstLetter(
                          lecture?.teacher?.firstName.substring(0, 1)
                        )}
                      </h3>
                      <div>
                        <LectureRatings
                          size="small"
                          color="#fff"
                          viewCount={lecture?.post_views}
                        />
                      </div>
                      <div className={classes.teacherDetailsLeft}>
                        <div>
                          <div
                            className={classes.moreIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <>
                              <LecturesFeedMenu
                                getFeed={getFeed}
                                onActionCallBack={onActionCallBack}
                                postId={lecture.id}
                                postTypeSave={
                                  lecture?.post_save == null &&
                                  identity?.userType !== "student" &&
                                  identity?.userId === lecture?.teacher_id
                                }
                                postTypeUnSave={lecture?.post_save !== null}
                                postTypeFavourite={
                                  lecture?.favourite_save == null
                                }
                                postTypeUnFavourite={
                                  lecture?.favourite_save !== null
                                }
                                postTypePlayList={
                                  identity.userType !== "student" &&
                                  identity.userId === lecture?.teacher_id
                                }
                                feedType={feedType}
                                onCallbackOfDeleteFeedPost={
                                  onCallbackOfDeleteFeedPost
                                }
                                lecture={lecture}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Lectures;
