import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@material-ui/core";
import AddPlayListModal from "../Dialogs/AddPlayListModal";
import { UIContext } from "../../contexts/UIContext";
import CF from "../../utils/CommonFunction";
import axiosInstance from "../../helpers/axiosInstance";
import Toast from "../../components/ui/toast/Toast";
import classes from "./playList.module.css";
import CloseIcon from "@material-ui/icons/Close";

const customStyles = {
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: "1.5rem",
    margin: 0,
  },
  closeButton: {
    cursor: "pointer",
  },
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    borderRadius: "8px",
    fontWeight: "bold",
    padding: "10px 20px",
    margin: "10px 0",
    transition: "background-color 0.3s ease",
  },
  listItem: {
    backgroundColor: "#f5f5f5",
    padding: "12px 16px",
    borderRadius: "8px",
    marginBottom: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  listItemHover: {
    backgroundColor: "#e0e0e0",
  },
};

export default function PlayListingModal({
  playlistModalOpen,
  setOpen,
  playListDetails,
  getPlayListDetails,
  postId,
  id,
  handleSubmit,
  submitFunction,
  feedFormData,
  identity,
}) {
  const [newPlaylistModalOpen, setNewPlaylistModalOpen] = useState(false);
  const { loading, setLoading } = useContext(UIContext);
  const [title, setTitle] = useState("");
  const [toast, setToast] = useState();

  useEffect(() => {
    setTitle("");
  }, [newPlaylistModalOpen]);

  useEffect(() => {
    if (newPlaylistModalOpen) {
      setOpen(false);
      getPlayListDetails(identity.userId);
    }
  }, [newPlaylistModalOpen]);

  const validateCreatePlayList = () => {
    let message = [];
    if (title.trim() === "") {
      message.push("Please, enter title.");
    }
    if (message.length > 0) {
      for (let i = 0; i < 1; i++) {
        setToast(CF.alert.error(message[i]));
      }
    }
    return message.length === 0;
  };

  const onClickOfPlayList = (e, playListId) => {
    if (typeof submitFunction === "undefined") {
      handleSubmit({
        ...feedFormData,
        playlist_id: playListId,
      });
    } else {
      submitFunction(e, "playlist", postId, playListId);
    }
  };

  const createPlayList = () => {
    const isValid = validateCreatePlayList();
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/playlist/save", { title })
        .then((res) => {
          if (res.data.state === true) {
            setToast(CF.alert.success(res.data.msg));
            getPlayListDetails(id);
            getPlayListDetails(identity.userId);
            setTitle("");
          } else {
            setToast(CF.alert.error(res.data.msg));
          }
        })
        .catch(() => {
          setToast(
            CF.alert.error("Something went wrong, while creating playlist.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={playlistModalOpen}
        onClose={() => setOpen(false)}
        maxWidth="sm"
      >
        <Box style={customStyles.modalHeader}>
          <DialogTitle id="alert-dialog-title" style={customStyles.dialogTitle}>
            <h3>Playlist</h3>
          </DialogTitle>
          <IconButton
            onClick={() => setOpen(false)}
            style={customStyles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent className={classes.dialogContent}>
          <Button
            style={customStyles.submitBtn}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor =
                customStyles.submitBtnHover.backgroundColor)
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor =
                customStyles.submitBtn.backgroundColor)
            }
            onClick={() => setNewPlaylistModalOpen(true)}
            fullWidth
          >
            Create New Playlist
          </Button>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {playListDetails?.map((item) => (
              <li
                key={item.id}
                style={customStyles.listItem}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    customStyles.listItemHover.backgroundColor)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor =
                    customStyles.listItem.backgroundColor)
                }
                onClick={(e) => onClickOfPlayList(e, item.id)}
              >
                {item?.title}
              </li>
            ))}
          </ul>
        </DialogContent>
      </Dialog>
      <AddPlayListModal
        open={newPlaylistModalOpen}
        setOpen={setNewPlaylistModalOpen}
        createPlayList={createPlayList}
        title={title}
        setTitle={setTitle}
        loading={loading}
        playListDetails={playListDetails}
        onClickOfPlayList={onClickOfPlayList}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
}
