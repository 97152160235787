import React from "react";
import logo from "../../assets/images/schooled_logo.png";
import classes from "./NavbarAdmin.module.css";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";

const NavbarAdmin = ({ logout, identity }) => {
  return (
    <>
      <header className={classes.navbar}>
        <div className={classes.left}>
          <Link
            to={
              identity.userType === "super"
                ? "/super-admin-dashboard"
                : "admin-dashboard"
            }
          >
            <img src={logo} className={classes.logo} alt="Schooled" />
          </Link>
        </div>
        <nav>
          <ul className={classes.navList}>
            <li className={classes.navItem} onClick={logout}>
              <LogoutIcon fontSize="large" className={classes.navIcon} />
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default NavbarAdmin;
