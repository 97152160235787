import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CF from "../../utils/CommonFunction";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import classes from "./LectureDetails.module.css";
import ViewRatingsModal from "./ViewRatingsModal";
import Toast from "../../components/ui/toast/Toast";
import LecturesFeedMenu from "../lectures/LecturesFeedMenu";
import youtubeIcon from "../../assets/images/youtubeIcon.png";
import LectureRatings from "../ui/lecture-ratings/LectureRatings";

function SkeletonLectures(_width) {
  return (
    <Grid container item spacing={0} direction="column">
      <div>
        <Skeleton
          animation="wave"
          height={380}
          className={classes.avatarLoader}
        />
      </div>
    </Grid>
  );
}

const LectureDetails = ({
  toast,
  handleClickCloseIcon,
  showVideo,
  onClickOfThumabilImage,
  feedDetail,
  navigate,
  setLoading,
  setToast,
  playVideo,
  identity,
  viewRatingsModalOpen,
  setViewRatingsModalOpen,
  feedMoreDetail,
  isLoading,
  onCallbackOfDeleteFeedPost,
  onActionCallBack,
  selectedFeedId = null,
  handleClick,
  getFeedMoreDetail,
}) => {
  const [showMoreCaption, setShowMoreCaption] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const formatBio = (bio) => {
    // Add spaces before uppercase letters that are not at the start of the string
    return bio.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/(.{50})/g, "$1 ");
  };

  const toggleReadMoreCaption = () => {
    setReadMore(!readMore);
  };

  const getReadMoreText = () => {
    if (readMore) {
      return feedDetail?.description;
    }
    return feedDetail?.description?.length > 500
      ? `${feedDetail.description.substring(0, 350)}`
      : feedDetail?.description;
  };

  const toggleShowMoreCaption = () => {
    setShowMoreCaption(!showMoreCaption);
  };

  const getCaptionText = () => {
    if (showMoreCaption) {
      return formatBio(feedDetail?.teacher?.bio);
    }
    return feedDetail?.teacher?.bio?.length > 500
      ? formatBio(feedDetail.teacher.bio.substring(0, 350))
      : formatBio(feedDetail?.teacher?.bio);
  };

  const filteredItems = feedMoreDetail?.filter(
    (item) => item.id !== selectedFeedId
  );

  return (
    <div className={classes.mainDiv}>
      <div className={classes.closeIconDiv}>
        {feedDetail?.title && (
          <div className={classes.closeIconWrapper}>
            <CloseIcon onClick={() => handleClickCloseIcon()} />
          </div>
        )}
        <div className={classes.teacherDetails}>
          <div className={classes.teacherDetailsTitleft}>
            <h1 className={classes.videoTitleText}>
              {CF.capitalizeFirstLetter(feedDetail?.title)}
            </h1>
          </div>
        </div>
      </div>
      <div className={classes.dashboard_video} >
        {showVideo ? (
          <iframe
            width="100%"
            height="100%"
            allow="fullscreen"
            src={`${feedDetail?.ytLink}${
              playVideo ? "?autoplay=1&mute=1" : ""
            }`}
          ></iframe>
        ) : (
          <div className={classes.videoMainDiv}>
            <img
              width="100%"
              height="100%"
              alt="image"
              src={`https://img.youtube.com/vi/${
                feedDetail?.video_id?.includes("?")
                  ? feedDetail?.video_id?.split("?")[0]
                  : feedDetail?.video_id
              }/hqdefault.jpg`}
              onClick={onClickOfThumabilImage}
            />
            <img
              alt="youtube_icon"
              src={youtubeIcon}
              className={classes.thumImage}
              onClick={onClickOfThumabilImage}
            />
          </div>
        )}
      </div>
      <div className={classes.videoDetailsWrapper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "14px",
              }}
            >
              <Avatar
                src={feedDetail?.teacher?.profileImage}
                loading="lazy"
                className={classes.profileImage}
                onClick={() =>
                  navigate(
                    "/teacher-profile/" + feedDetail.teacher_id + "/lectures"
                  )
                }
              >
                {CF.capitalizeFirstLetter(
                  feedDetail?.teacher?.firstName?.substring(0, 1)
                )}
              </Avatar>
              {feedDetail?.teacher?.firstName && (
                <h6
                  className={classes.profileNameText}
                  onClick={() =>
                    navigate(
                      "/teacher-profile/" + feedDetail.teacher_id + "/lectures"
                    )
                  }
                  title={CF.capitalizeFirstLetter(
                    feedDetail?.teacher?.firstName
                  )}
                >
                  {feedDetail.teacher.teacher_title
                    ? feedDetail.teacher.teacher_title
                    : "Prof."}{" "}
                  {CF.capitalizeFirstLetter(feedDetail?.teacher?.firstName)}
                  &nbsp;
                  {feedDetail?.teacher?.lastName}
                </h6>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <p className={classes.descriptionDeatils}>
                DESCRIPTION:&nbsp;
                {feedDetail?.description ? (
                  <span>
                    {getReadMoreText()}
                    {feedDetail?.description?.length > 300 && (
                      <span
                        className={classes.readMoreText}
                        onClick={toggleReadMoreCaption}
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {readMore ? "Read Less" : "Read More"}
                      </span>
                    )}
                  </span>
                ) : (
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      opacity: "0.3",
                      marginTop: "2px",
                    }}
                  >
                    <p>No description provided</p>
                  </div>
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.aboutMe}>
              ABOUT ME:&nbsp;
              {feedDetail?.teacher?.bio ? (
                <span>
                  {getCaptionText()}
                  {feedDetail?.teacher?.bio?.length > 300 && (
                    <span
                      className={classes.showMoreText}
                      onClick={toggleShowMoreCaption}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {showMoreCaption ? "Read Less" : "Read More"}
                    </span>
                  )}
                </span>
              ) : (
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                    opacity: "0.3",
                    marginTop: "2px",
                  }}
                >
                  <p>No information available</p>
                </div>
              )}
            </p>
          </Grid>
        </Grid>
        <Divider className={classes.scDivider} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h4 className={classes.moreInfo}>
              More from{" "}
              {feedDetail?.teacher?.teacher_title
                ? feedDetail?.teacher?.teacher_title
                : "Prof."}{" "}
              {CF.capitalizeFirstLetter(feedDetail?.teacher?.firstName)}.
            </h4>
            <Grid container spacing={2} className={classes.paperMainDiv}>
              {Array.from(new Array(isLoading ? 6 : 0)).map((a, i) => (
                <div
                  item
                  key={i}
                  style={{
                    width: "50%",
                    padding: "0 10px 11px",
                  }}
                >
                  <SkeletonLectures key={i} />
                </div>
              ))}
              {filteredItems?.length > 0 ? (
                filteredItems.map((lecture) => (
                  <Grid item xs={6} key={lecture.id}>
                    <div
                      className={classes.lectureThumbnailItem}
                      onClick={(e) =>
                        handleClick(
                          lecture.id,
                          lecture.teacher_id,
                          lecture.slug
                        )
                      }
                    >
                      <div className={classes.imageMainDiv}>
                        <img
                          alt=""
                          src={`https://img.youtube.com/vi/${
                            lecture?.video_id?.includes("?")
                              ? lecture?.video_id?.split("?")[0]
                              : lecture?.video_id
                          }/hqdefault.jpg`}
                          className={classes.thumbnailImage}
                        />
                      </div>
                      <div className={classes.bottomTab}>
                        <div className={classes.bottomDiv}>
                          <Avatar
                            src={lecture?.teacher?.profileImage}
                            loading="lazy"
                            className={classes.profileImage}
                            onClick={() =>
                              navigate(
                                "/teacher-profile/" +
                                  lecture?.teacher_id +
                                  "/lectures"
                              )
                            }
                          ></Avatar>
                          <div
                            className={classes.ThubCaptionText}
                            onClick={() => {
                              handleClick(
                                lecture.id,
                                lecture.teacher_id,
                                lecture.slug
                              );
                            }}
                          >
                            <div
                              onClick={(e) =>
                                handleClick(
                                  lecture.id,
                                  lecture.teacher_id,
                                  lecture.slug
                                )
                              }
                            >
                              <h3 title={lecture.title}>{lecture.title}</h3>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                                onClick={(e) =>
                                  handleClick(
                                    lecture.id,
                                    lecture.teacher_id,
                                    lecture.slug
                                  )
                                }
                              >
                                <h3
                                  className={classes.nameText}
                                  title={lecture.teacher.firstName}
                                >
                                  {lecture.teacher.teacher_title
                                    ? lecture.teacher.teacher_title
                                    : "Prof"}
                                  .{" "}
                                  {CF.capitalizeFirstLetter(
                                    lecture.teacher.firstName.substring(0, 1)
                                  )}
                                </h3>
                                <div className={classes.ratingDiv}>
                                  <LectureRatings
                                    size="small"
                                    color="#fff"
                                    viewCount={lecture?.post_views}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={classes.teacherDetailsLeft}>
                              <div>
                                <div
                                  className={classes.moreIcon}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <>
                                    <LecturesFeedMenu
                                      getFeedMoreDetail={getFeedMoreDetail}
                                      feedDetail={feedDetail}
                                      onActionCallBack={onActionCallBack}
                                      postId={lecture.id}
                                      postTypeSave={
                                        lecture.post_save == null &&
                                        identity.userType !== "student" &&
                                        identity.userId === lecture.teacher_id
                                      }
                                      postTypeUnSave={
                                        lecture.post_save !== null
                                      }
                                      postTypeFavourite={
                                        lecture.favourite_save == null
                                      }
                                      postTypeUnFavourite={
                                        lecture.favourite_save !== null
                                      }
                                      postTypePlayList={
                                        identity.userType !== "student" &&
                                        identity.userId === lecture.teacher_id
                                      }
                                      onCallbackOfDeleteFeedPost={
                                        onCallbackOfDeleteFeedPost
                                      }
                                      lecture={lecture}
                                    />
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))
              ) : (
                <div className={classes.noDataFoundMainDiv}>
                  <div className={classes.nodataText}>No other posts yet</div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ViewRatingsModal
        open={viewRatingsModalOpen}
        setOpen={setViewRatingsModalOpen}
        setLoading={setLoading}
        identity={identity}
        feedDetail={feedDetail}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default LectureDetails;
