import React from "react";
import { Grid } from "@material-ui/core";
import classes from "./Search.module.css";
import LectureRatings from "./lecture-ratings/LectureRatings";
import notFoundIcon from "../../assets/images/norecordfound.png";
import Avatar from "@material-ui/core/Avatar";
import CF from "../../utils/CommonFunction";
import Skeleton from "@mui/material/Skeleton";

function SkeletonLectures(_width) {
  return (
    <Grid container item spacing={0} direction="column">
      <div>
        <Skeleton
          animation="wave"
          height={500}
          className={classes.avatarLoader}
        />
      </div>
    </Grid>
  );
}

function Search({ isLoading, searchData, navigate }) {
  return (
    <>
      <Grid container>
        <>
          {searchData?.length === 0 && (
            <div className={classes.noRecordFoundMainDiv}>
              <div className={classes.noRecordFoundText}>No record found.</div>

              <img src={notFoundIcon} className={classes.noRecordFoundImag} />
            </div>
          )}
        </>
        {Array.from(new Array(isLoading ? 20 : 0)).map((a, i) => (
          <Grid className={classes.container} lg={3} md={6} sm={6}>
            <div
              item
              key={i}
              style={{
                width: "100%",
              }}
            >
              <SkeletonLectures key={i} />
            </div>
          </Grid>
        ))}
        <>
          {searchData?.map((item) => (
            <Grid className={classes.container} lg={3} md={6} sm={6}>
              <div className={classes.lectureThumbnailItem}>
                <div className={classes.thumImageDiv}>
                  <img
                    alt=""
                    src={`https://img.youtube.com/vi/${
                      item?.video_id?.includes("?")
                        ? item?.video_id?.split("?")[0]
                        : item?.video_id
                    }/hqdefault.jpg`}
                    className={classes.thumImage}
                    onClick={(e) =>
                      navigate(
                        `/dashboard/${item.slug}?from=search&item=${item.id}`
                      )
                    }
                  />
                </div>
                <div className={classes.teacherDetailsLeft}></div>
                <div className={classes.centeText}>
                  teacher’s thumbnail here
                </div>
                <div className={classes.bottomTab}>
                  <div className={classes.bottomUserImage}>
                    <Avatar
                      src={item.teacher.profileImage}
                      loading="lazy"
                      className={classes.profileImage}
                      onClick={() =>
                        navigate(
                          "/teacher-profile/" + item.teacher_id + "/lectures"
                        )
                      }
                    ></Avatar>
                    <div
                      className={classes.ThubCaptionText}
                      onClick={(e) =>
                        navigate(
                          `/dashboard/${item.slug}?from=search&item=${item.id}`
                        )
                      }
                    >
                      <p>{item.title}</p>
                      <div className={classes.ratingDiv}>
                        <LectureRatings
                          size="small"
                          color="#fff"
                          viewCount={item?.post_views}
                        />
                      </div>
                    </div>
                  </div>
                  <h3
                    className={classes.nameText}
                    title={item.teacher.firstName}
                  >
                    Prof. &nbsp;
                    {CF.capitalizeFirstLetter(
                      item.teacher.firstName.substring(0, 1)
                    )}
                  </h3>
                </div>
              </div>
            </Grid>
          ))}
        </>
      </Grid>
    </>
  );
}

export default Search;
