import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

const customStyles = {
  submitBtn: {
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    marginTop: "17px",
    marginRight: "20px",
  },
};

export default function EraseChannelModal({
  open,
  setOpen,
  loading,
  handleClickEraseChannel,
  teacher,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to erase this channel?
        </DialogTitle>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button style={customStyles.submitBtn} onClick={() => setOpen(false)}>
            No
          </Button>
          <Button
            onClick={(e) => handleClickEraseChannel(teacher.userId)}
            style={customStyles.submitBtn}
            disabled={loading}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
