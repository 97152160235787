import React, { useState, useEffect, useContext } from "react";
import CF from "../../utils/CommonFunction";
import classes from "./FavoriteTeachers.module.css";
import axiosInstance from "../../helpers/axiosInstance";
import { UIContext } from "../../contexts/UIContext";
import { useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import defaultImage from "../../assets/images/defaultimage.png";

const FavoriteTeachers = () => {
  const { setLoading } = useContext(UIContext);
  const [teacherFollowerDetails, setTeacherFollowerDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTeacherFollowDetails();
  }, []);

  const getTeacherFollowDetails = () => {
    setLoading(true);
    axiosInstance
      .get("/student/follow-teacher")
      .then((res) => {
        setTeacherFollowerDetails(res.data.teachers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        {teacherFollowerDetails.map((item) => (
          <Grid item xs={4} md={4}>
            {teacherFollowerDetails?.length === 0 && (
              <div
                style={{
                  padding: "1rem",
                  margin: "0 auto",
                  fontSize: "2rem",
                  opacity: "0.3",
                }}
              >
                No teacher follow available
              </div>
            )}
            <div className={classes.teacher} key={item.id}>
              <img
                referrerpolicy="no-referrer"
                src={item.profileImage ? item.profileImage : defaultImage}
                loading="lazy"
                className={classes.profileImage}
                onClick={() =>
                  navigate("/teacher-profile/" + item.userId + "/lectures")
                }
              />
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <h4 className={classes.nameText}>
                    {item.teacher_title ? item.teacher_title : "Prof"}.{" "}
                    {CF.capitalizeFirstLetter(item.firstName)}
                  </h4>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  <label className={classes.subject}>{item.subject}</label>
                </Grid>
              </Grid>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default FavoriteTeachers;
