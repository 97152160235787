import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid } from "@material-ui/core";
import classes from "./Dashboard.module.css";
import SearchBar from "./SearchBar";
import LecturesContainer from "../../containers/LecturesContainer";
import LectureDetailsContainer from "../../containers/LectureDetailsContainer";
import axiosInstance from "../../helpers/axiosInstance";
import Toast from "../../components/ui/toast/Toast";
import { AuthContext } from "../../contexts/AuthContext";
import CF from "../../utils/CommonFunction";
import { useSearchParams } from "react-router-dom";
import { UIContext } from "../../contexts/UIContext";

const Dashboard = () => {
  const [selectedFeedId, setSelectedFeedId] = useState(0);
  const [mainFeed, setMainFeed] = useState([]);
  const [followingFeed, setFollowingFeed] = useState([]);
  const [favouriteFeed, setFavouriteFeed] = useState([]);
  const [toast, setToast] = useState();
  const { identity } = useContext(AuthContext);
  const [queryStringParam, setQueryStringParam] = useSearchParams();
  const { setLoading } = useContext(UIContext);
  const [loadingYourFeed, setLoadingLoadingYourFeed] = useState(false);
  const [loadingFollowingFeed, setLoadingFollowingFeed] = useState(false);
  const [loadingFavouirteFeed, setLoadingFavouirteFeed] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false); // New state to control scroll

  // Ref to scroll the container
  const lecturesContainerRef = useRef(null);

  const viewSelectedFeedDetail = (feedId) => {
    if (lecturesContainerRef.current) {
      lecturesContainerRef.current.scrollIntoView({ behavior: "smooth" });

      // Add a slight delay before showing the feed detail (allow time for scrolling to finish)
      setTimeout(() => {
        setSelectedFeedId(feedId); // Now set the feedId after scroll
      }, 300); // Adjust timeout duration as necessary (300ms is typically enough)
    } else {
      setSelectedFeedId(feedId); // If ref is not available, set it immediately
    }
  };

  const onSearchEvent = (e, feedType) => {
    // setLoading(true);
    getFeed(feedType, e.target.value);
  };

  const getFeed = (feedType, searchText) => {
    searchText = searchText || "";
    let apiEndPoint = null;
    if (feedType === "main-feed") {
      setLoadingLoadingYourFeed(true);
      apiEndPoint = "/post/feed?q=" + searchText;
    } else if (feedType === "following-feed") {
      setLoadingFollowingFeed(true);
      apiEndPoint = "/post/follow-teacher?q=" + searchText;
    } else if (feedType === "favourite-feed") {
      setLoadingFavouirteFeed(true);
      apiEndPoint = `/post/save?type=favorite&user_type=${identity.userType}&q=${searchText}`;
    }
    axiosInstance
      .get(apiEndPoint)
      .then((res) => {
        if (feedType === "main-feed") {
          setMainFeed(CF.assignRandomColorCodeInArray(res.data.feed));
        } else if (feedType === "following-feed") {
          setFollowingFeed(CF.assignRandomColorCodeInArray(res.data.feeds));
        } else if (feedType === "favourite-feed") {
          setFavouriteFeed(CF.assignRandomColorCodeInArray(res.data.feeds));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        if (feedType === "main-feed") {
          setLoadingLoadingYourFeed(false);
        } else if (feedType === "following-feed") {
          setLoadingFollowingFeed(false);
        } else if (feedType === "favourite-feed") {
          setLoadingFavouirteFeed(false);
        }
      });
  };

  const updateActionInState = (feedType, feedId, actionType) => {
    let feedData = [];
    if (feedType === "main-feed") {
      feedData = mainFeed;
    } else if (feedType === "favourite-feed") {
      feedData = favouriteFeed;
    } else if (feedType === "following-feed") {
      feedData = followingFeed;
    }
    let _mainFeed = feedData.map(function (singleFeed) {
      if (singleFeed.id === feedId) {
        if (actionType === "favorite") {
          singleFeed.favourite_save = {};
        } else if (actionType === "save") {
          singleFeed.post_save = {};
        }
      }
      return singleFeed;
    });
    if (feedType === "main-feed") {
      setMainFeed(_mainFeed);
    } else if (feedType === "favourite-feed") {
      setFavouriteFeed(_mainFeed);
    } else if (feedType === "following-feed") {
      setFollowingFeed(_mainFeed);
    }
  };

  const onActionCallBack = (actionStatus, actionType, additionalValue) => {
    const feedType = additionalValue.feedType;
    const feedId = additionalValue.requestData.post_id;
    if (actionStatus === 1) {
      updateActionInState(feedType, feedId, actionType);
      if (actionType === "favorite") {
        getFeed("favourite-feed");
      } else if (actionType === "favorite") {
      } else if (actionType === "un-favorite") {
        getFeed("favourite-feed");
        getFeed("main-feed");
        getFeed("following-feed");
      } else if (actionType === "save") {
        getFeed("favourite-feed");
        getFeed("main-feed");
        getFeed("following-feed");
      } else if (actionType === "un-save") {
        getFeed("favourite-feed");
        getFeed("main-feed");
        getFeed("following-feed");
      }
    }
  };

  useEffect(() => {
    if (shouldScroll && lecturesContainerRef.current) {
      lecturesContainerRef.current.scrollIntoView({ behavior: "smooth" });
      setShouldScroll(false); // Reset scroll flag after scrolling
    }
  }, [mainFeed, followingFeed, favouriteFeed, shouldScroll]);

  useEffect(() => {
    if (queryStringParam.has("from") && queryStringParam.has("item")) {
      viewSelectedFeedDetail(queryStringParam.get("item"));
      queryStringParam.delete("from");
      queryStringParam.delete("item");
      setQueryStringParam(queryStringParam);
    }
    getFeed("main-feed");
    getFeed("following-feed");
    getFeed("favourite-feed");
  }, []);

  const onCallbackOfDeleteFeedPost = () => {
    getFeed("main-feed");
    getFeed("favourite-feed");
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ height: "calc(100vh - 90px)" }}
    >
      <Grid
        item
        lg={selectedFeedId > 0 ? 3 : 6}
        md={selectedFeedId > 0 ? 3 : 6}
        sm={12}
        className={classes.dashboardItem}
      >
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9,
            background: "white",
            paddingBottom: "10px",
            borderBottom: "1px solid gray",
          }}
        >
          <h3 className={classes.headerText}>Your Feed</h3>
          <SearchBar onSearchEvent={onSearchEvent} feedType={"main-feed"} />
        </div>
        <div className={classes.scrollView} ref={lecturesContainerRef}>
          <LecturesContainer
            lectures={mainFeed}
            lectureItemClickHandler={viewSelectedFeedDetail}
            onActionCallBack={onActionCallBack}
            feedType={"main-feed"}
            onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
            getFeed={getFeed}
            blockWidth={selectedFeedId > 0 ? "100%" : "50%"}
            // mainFeedData={"50%"}
            isLoading={loadingYourFeed}
          />
        </div>
      </Grid>
      <Grid item lg={3} md={3} sm={12} className={classes.dashboardItem}>
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9,
            background: "white",
            paddingBottom: "10px",
            borderBottom: "1px solid gray",
          }}
        >
          <h3 className={classes.headerText}>
            {selectedFeedId > 0 ? "Favorites" : "Following"}
          </h3>
          <SearchBar
            onSearchEvent={onSearchEvent}
            feedType={"following-feed"}
          />
        </div>
        <div className={classes.scrollView} ref={lecturesContainerRef}>
          {/* When no detail view selected then show  Following else Favorites*/}
          {selectedFeedId === 0 ? (
            <LecturesContainer
              lectures={followingFeed}
              lectureItemClickHandler={viewSelectedFeedDetail}
              onActionCallBack={onActionCallBack}
              feedType="following-feed"
              getFeed={getFeed}
              mainFollowingData={"100%"}
              isLoading={loadingFollowingFeed}
            />
          ) : (
            <LecturesContainer
              lectures={favouriteFeed}
              lectureItemClickHandler={viewSelectedFeedDetail}
              onActionCallBack={onActionCallBack}
              feedType="following-feed"
              getFeed={getFeed}
              isLoading={loadingFollowingFeed}
            />
          )}
        </div>
      </Grid>
      <Grid
        item
        lg={selectedFeedId > 0 ? 6 : 3}
        md={selectedFeedId > 0 ? 6 : 3}
        sm={12}
        className={classes.dashboardItem}
      >
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9,
            background: "white",
          }}
        >
          {selectedFeedId === 0 && (
            <h3 className={classes.headerText}>Favorites</h3>
          )}
          {selectedFeedId === 0 && (
            <>
              <SearchBar
                onSearchEvent={onSearchEvent}
                feedType={"favourite-feed"}
              />
              <div
                style={{ borderBottom: "1px solid grey", marginTop: "10px" }}
              ></div>
            </>
          )}
        </div>
        <div className={classes.scrollView} ref={lecturesContainerRef}>
          {selectedFeedId > 0 ? (
            <LectureDetailsContainer
              setLectureDetailsOpen={viewSelectedFeedDetail}
              selectedFeedId={selectedFeedId}
              getFeed={getFeed}
              lectures={mainFeed}
              onActionCallBack={onActionCallBack}
              onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
            />
          ) : (
            <LecturesContainer
              lectures={favouriteFeed}
              lectureItemClickHandler={viewSelectedFeedDetail}
              onActionCallBack={onActionCallBack}
              feedType="favourite-feed"
              getFeed={getFeed}
              mainFavouiteData={"100%"}
              isLoading={loadingFavouirteFeed}
            />
          )}
        </div>
      </Grid>
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </Grid>
  );
};

export default Dashboard;
