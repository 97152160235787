import React from "react";
import PlayIcon from "@material-ui/icons/PlayCircleFilledRounded";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import classes from "./TeacherControls.module.css";

const TeacherControls = ({ activeControl, onChangeActiveTabs }) => {
  return (
    <div className={classes.container}>
      <div
        className={`${classes.control} ${
          activeControl === "lectures" && classes.active
        }`}
        onClick={() => onChangeActiveTabs("lectures")}
      >
        <PlayIcon fontSize="large" style={{ color: "white" }} />
        <label>View Lectures</label>
      </div>
      <div
        className={`${classes.control} ${
          activeControl === "playlist" && classes.active
        }`}
        onClick={() => onChangeActiveTabs("playlist")}
      >
        <PlaylistPlayIcon fontSize="large" style={{ color: "white" }} />
        <label>Playlists</label>
      </div>
    </div>
  );
};

export default TeacherControls;
