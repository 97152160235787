import React, { useState } from "react";
import classes from "./Teachers.module.css";
import noDataImage from "../../../assets/illustrations/no_data.png";
import Avatar from "@material-ui/core/Avatar";
import CF from "../../../utils/CommonFunction";
import EditIcon from "@material-ui/icons/Edit";
import InstutionUpdateTeacherDetailsModal from "../../Dialogs/InstutionUpdateTeacherDetailsModal";
import axiosInstance from "../../../helpers/axiosInstance";
import { Grid } from "@material-ui/core";

const Teachers = ({
  teachers,
  viewSelectedTeacherDetail,
  setToast,
  setLoading,
  getTeacherListing,
  details,
  setTeacherListing,
}) => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [editTeacherInfo, setEditTeacherInfo] = useState({});
  const [teacherData, setTeacherData] = useState({
    firstName: "",
    lastName: "",
    subject: "",
    teacher_title: "",
  });

  const validateUpdateProfileDetail = () => {
    let message = [];
    const nameRegex = /^[A-Za-z\s]+$/; // Regex to allow only alphabet characters and spaces

    //  Check if first name is empty
    if (teacherData.firstName === "") {
      message.push("Please enter first name.");
    }
    // Check if first name contains only letters
    else if (!nameRegex.test(teacherData.firstName)) {
      message.push(
        "First name can only contain letters. Numbers and special characters are not allowed."
      );
    }
    // Check if subject is empty
    if (teacherData.subject === "") {
      message.push("Please enter subject.");
    } // Check if subject contains only letters
    else if (!nameRegex.test(teacherData.subject)) {
      message.push(
        "Subject can only contain letters. Numbers and special characters are not allowed."
      );
    }

    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  const updateTeacherDetails = (e, editTeacherId) => {
    e.preventDefault();
    const isValid = validateUpdateProfileDetail();
    if (isValid) {
      setUpdateModalOpen(false);
      setLoading(false);
      axiosInstance
        .put("/teacher/detail?user_id=" + editTeacherId, teacherData)
        .then((res) => {
          //  array.map((item)=>{

          //  });

          //editTeacherInfo
          getTeacherListing(details.userId);
          setToast(CF.alert.success("Teacher details updated successfully"));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTeacherDataChange = (e) => {
    setTeacherData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onEditTeacher = (teacherObject) => {
    setTeacherData((prev) => ({
      ...prev,
      firstName: teacherObject.firstName,
      subject: teacherObject.subject,
      teacher_title: teacherObject.teacher_title,
    }));
    setEditTeacherInfo(teacherObject);
    setUpdateModalOpen(true);
  };

  return (
    <>
      <div className={classes.maindiv}>
        <h2 className={classes.headerText}>Teachers ({teachers.length})</h2>
        {teachers?.length > 0 ? (
          <>
            {teachers.map((teacher) => (
              <div key={teacher.id} className={classes.teacher}>
                <Avatar
                  src={teacher.profileImage}
                  loading="lazy"
                  style={{
                    height: "4rem",
                    width: "4rem",
                  }}
                  className={classes.teacherImg}
                  onClick={() => viewSelectedTeacherDetail(teacher?.userId)}
                >
                  {CF.capitalizeFirstLetter(teacher.firstName?.substring(0, 1))}
                </Avatar>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={8} md={6}>
                    <label
                      onClick={() => viewSelectedTeacherDetail(teacher?.userId)}
                    >
                      {teacher.teacher_title ? teacher.teacher_title : "Prof"}.{" "}
                      {teacher.firstName}
                    </label>
                  </Grid>
                  <Grid item xs={3} md={4}>
                    <EditIcon
                      onClick={() => onEditTeacher(teacher)}
                      style={{ float: "right" }}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} md={9}>
                      <p
                        onClick={() =>
                          viewSelectedTeacherDetail(teacher?.userId)
                        }
                        style={{ marginBottom: "5px" }}
                      >
                        {teacher?.institution?.name}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <p
                        onClick={() =>
                          viewSelectedTeacherDetail(teacher?.userId)
                        }
                      >
                        Teaches {teacher.subject}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ))}
          </>
        ) : (
          <div className={classes.noDataDiv}>
            <img
              className={classes.noDataImg}
              src={noDataImage}
              alt="no teachers illustration"
            />
            <p>No Teachers Found</p>
          </div>
        )}
      </div>
      <InstutionUpdateTeacherDetailsModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        teacherData={teacherData}
        handleTeacherDataChange={handleTeacherDataChange}
        handleSubmit={updateTeacherDetails}
        editTeacherInfo={editTeacherInfo}
      />
    </>
  );
};

export default Teachers;
