import { Grid, useMediaQuery, useTheme, TextField } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import InstitutionIcon from "@material-ui/icons/LocationCity";
import DomainIcon from "@material-ui/icons/AlternateEmail";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import axiosInstance from "../../../helpers/axiosInstance";
import classes from "./Dashboard.module.css";
import Teachers from "./Teachers";
import TeacherDetails from "./TeacherDetails";
import TeacherList from "./TeacherList";
import { useParams, useNavigate } from "react-router-dom";
import { UIContext } from "../../../contexts/UIContext";
import Toast from "../../../components/ui/toast/Toast";
import CF from "../../../utils/CommonFunction";
import { AuthContext } from "../../../contexts/AuthContext";
// import CreateIcon from "@material-ui/icons/Create";
import EditInstitutionDetailsModal from "../../Dialogs/EditInstitutionDetailsModal";
import DeleteInstitutionModal from "../../Dialogs/DeleteInstitutionModal";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Avatar from "@material-ui/core/Avatar";
import noDataImage from "../../../assets/illustrations/no_data.png";
import StudentDetails from "../../admin/dashboard/StudentDetails";

const Dashboard = () => {
  const navigate = useNavigate();
  const { institutionId } = useParams();
  const { loading, setLoading } = useContext(UIContext);
  const [email, setEmail] = useState("");
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [teacherList, setTeacherList] = useState([]);
  const [newTeacherList, setTewTeacherList] = useState([]);
  const [teacherEmails, setTeacherEmails] = useState([]);
  const [toast, setToast] = useState();
  const [teacherListing, setTeacherListing] = useState([]);
  const { identity } = useContext(AuthContext);
  const [authorizedDomains, setAuthorizedDomains] = useState([]);
  const [editInstitutionModalOpen, setEditInstitutionModalOpen] =
    useState(false);

  const viewSelectedTeacherDetail = (teacherId) => {
    setSelectedTeacherId(teacherId);
    setSelectedStudentId("");
  };
  const [updateInstitutionDetails, setUpdateInstitutionDetails] = useState({
    name: "",
    address: "",
  });
  const [details, setDetails] = useState({
    name: "",
    address: "",
    domain: "",
    teacherList: [],
  });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteInstitutionModalOpen, setDeleteInstitutionModalOpen] =
    useState(false);
  const [studentDetail, setStudentDetail] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedStudent, setSelectedStudent] = useState({});

  const viewSelectedStudentDetail = (studentId, student) => {
    setSelectedStudentId(studentId);
    setSelectedStudent(student);
    setSelectedTeacherId("");
  };

  useEffect(() => {
    if (editInstitutionModalOpen === false) {
      setUpdateInstitutionDetails({
        name: details.name,
        address: details.address,
      });
    }
  }, [editInstitutionModalOpen]);

  const handleChangeInstitutionDetails = (e) => {
    setUpdateInstitutionDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  const getInstitutions = () => {
    setLoading(true);
    axiosInstance
      .get("/super/institutions")
      .then((res) => {
        setAuthorizedDomains(res.data.institutions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (!institutionId) {
      getAdminDetails();
      getTeacherList();
      getTeacherListing(identity.userId);
    } else {
      getInstitutionDetails();
    }
  }, []);

  useEffect(() => {
    if (details.userId) {
      getTeacherListing(details.userId);
      getStudentDetails(details.userId);
    }
  }, [details]);

  //Listing API For Teacher
  const getTeacherListing = (institutionId) => {
    axiosInstance
      .get("/teacher/lists?institution_id=" + institutionId)
      .then((res) => {
        setTeacherListing(res.data.teachers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //Student List api
  const getStudentDetails = (institutionId) => {
    setLoading(true);
    axiosInstance
      .get("/student/lists?institution_id=" + institutionId)
      .then((res) => {
        setStudentDetail(res.data.students);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAdminDetails = () => {
    axiosInstance
      .get("/admin/details")
      .then((res) => {
        setDetails(res.data.institution);
        const { name, address } = res.data.institution;
        setLoading(false);
        setUpdateInstitutionDetails({
          name,
          address,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTeacherList = () => {
    axiosInstance
      .get("/admin/teacherList")
      .then((res) => {
        setTeacherList(res.data.teacherList);
        setTeacherEmails(res.data.teacherList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getInstitutionDetails = () => {
    axiosInstance
      .get(`/super/institution/${institutionId}`)
      .then((res) => {
        let institionData = res.data.institution;
        institionData.teacherList =
          institionData.teacherList === null
            ? []
            : JSON.parse(institionData.teacherList);
        const { name, address } = res.data.institution;
        setUpdateInstitutionDetails({
          name,
          address,
        });
        //Set institionData state
        setTeacherList(institionData.teacherList);
        setDetails(institionData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const saveTeacherList = () => {
    setLoading(true);
    if (identity.userType === "admin") {
      axiosInstance
        .put("/admin/teacherList", {
          teacherEmails: teacherList,
          newTeacherList,
        })
        .then((res) => {
          if (res.data.status === false) {
            setToast(CF.alert.error(`${res.data.msg} (${res.data.data})`));
            setLoading(false);
          } else {
            getTeacherListing(details.userId);
            getStudentDetails(details.userId);
            setToast(CF.alert.success("Teacher list updated"));
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else if (identity.userType === "super") {
      axiosInstance
        .put("/admin/teacherList?institution_id=" + details.userId, {
          teacherEmails: teacherList,
          newTeacherList,
        })
        .then((res) => {
          if (res.data.status === false) {
            setToast(CF.alert.error(`${res.data.msg} (${res.data.data})`));
            setLoading(false);
          } else {
            getTeacherListing(details.userId);
            getStudentDetails(details.userId);
            setToast(CF.alert.success("Teacher list updated"));
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const onCallbackOfEraseChannel = () => {
    getTeacherListing(details.userId);
    getTeacherList();
    setSelectedTeacherId("");
    getInstitutionDetails();
  };

  //Validation for Institution detail
  const validateUpdateInstitutionDetail = () => {
    let message = [];
    if (updateInstitutionDetails.name === "") {
      message.push("Please enter Institution name.");
    }
    if (updateInstitutionDetails.address === "") {
      message.push("Please enter address.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  //Edit institution details
  const editInstitutionDetails = (e) => {
    e.preventDefault();
    const isValid = validateUpdateInstitutionDetail();
    if (isValid) {
      setEditInstitutionModalOpen(false);
      setLoading(true);
      axiosInstance
        .put(
          "/super/institution?institution_id=" + identity.userId,
          updateInstitutionDetails
        )
        .then((res) => {
          setDetails({
            ...details,
            name: updateInstitutionDetails.name,
            address: updateInstitutionDetails.address,
          });
          setLoading(false);
          setToast(CF.alert.success("institution updated successfully"));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleClickDeleteInstitution = (institutionId) => {
    setLoading(true);
    axiosInstance
      .get("/admin/remove-institution?institution_id=" + institutionId)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          navigate("/super-admin-dashboard");
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        setToast(
          CF.alert.error("Something went wrong, while delete institution.")
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCallbackOfUserTypeChange = () => {
    setSelectedStudentId("");
    getStudentDetails(details.userId);
    getTeacherListing(details.userId);
    getInstitutionDetails();
  };

  //Validation for sent invite email
  const validateInviteEmail = () => {
    let message = [];
    if (email === "") {
      message.push("Please enter email.");
    }
    if (!CF.isValidEmail(email)) {
      message.push("Please enter valid email format.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  const inviteAdmin = (e) => {
    const isValid = validateInviteEmail();
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/super/invite", { email })
        .then((res) => {
          setEmail("");
          setToast(CF.alert.success(res.data));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setEmail("");
          setLoading(false);
          if (err.response && err.response.data) {
            setToast({
              status: "error",
              title: "Invite Not Sent",
              body: err.response.data.msg,
            });
          }
        });
    }
  };

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch {
      return [str];
    }
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={isSmall ? 0 : 0.5}
        className={classes.containerGrid}
        style={{ height: "100%" }}
      >
        <Grid item lg={3} md={12} sm={12} className={classes.gridBoder}>
          {!institutionId && (
            <h5 className={classes.welcomeText}>Welcome, Admin</h5>
          )}
          <div className={classes.institutionDetails}>
            <div className={classes.iconText}>
              <InstitutionIcon fontSize="medium" className={classes.icon} />
              <h3 className={classes.institutionName}>{details.name}</h3>
              {identity.userType === "super" && (
                <div
                  style={{
                    marginTop: "1px",
                    marginLeft: "20px",
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title="Delete Institution">
                    <DeleteIcon
                      onClick={() => setDeleteInstitutionModalOpen(true)}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            <div className={classes.iconText}>
              <DomainIcon
                fontSize="small"
                className={classes.icon}
                style={{ color: "grey" }}
              />
              <p className={classes.address}>
                {details.domain != ""
                  ? JSON.parse(details.domain).join(", ")
                  : ""}
              </p>
            </div>
            <div className={classes.iconText}>
              <LocationIcon
                fontSize="small"
                className={classes.icon}
                style={{ color: "grey" }}
              />
              <p className={classes.address}>{details.address}</p>
            </div>
          </div>
          <Teachers
            teachers={teacherListing}
            viewSelectedTeacherDetail={viewSelectedTeacherDetail}
            setToast={setToast}
            setLoading={setLoading}
            getTeacherListing={getTeacherListing}
            details={details}
            setTeacherListing={setTeacherListing}
          />
        </Grid>
        <Grid item lg={3} md={12} sm={12} className={classes.gridBoder}>
          <h2 className={classes.headerText}>
            Students ({studentDetail.length})
          </h2>
          {studentDetail?.length > 0 ? (
            <ul className={classes.institutionList}>
              {studentDetail.map((student) => (
                <>
                  <li
                    className={classes.studentItem}
                    key={student.id}
                    onClick={() =>
                      viewSelectedStudentDetail(student.userId, student)
                    }
                  >
                    <Avatar
                      src={student?.profileImage}
                      loading="lazy"
                      style={{
                        height: "4rem",
                        width: "4rem",
                      }}
                      onClick={() =>
                        viewSelectedStudentDetail(student.userId, student)
                      }
                    >
                      {CF.capitalizeFirstLetter(
                        student.firstName?.substring(0, 1)
                      )}
                    </Avatar>
                    <span>
                      {CF.capitalizeFirstLetter(student.firstName)}
                      &nbsp;
                      {student.lastName}
                      <p
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        {student?.institution?.name}
                      </p>
                    </span>
                  </li>
                </>
              ))}
            </ul>
          ) : (
            <div className={classes.noDataDiv}>
              <img
                className={classes.noDataImg}
                src={noDataImage}
                alt="no teachers illustration"
              />
              <p>No Student Found</p>
            </div>
          )}
        </Grid>
        <Grid
          item
          lg={identity.userType === "super" ? 4 : 6}
          md={12}
          sm={12}
          className={classes.gridBoder}
        >
          <div style={{ padding: "1rem" }}>
            {selectedTeacherId ? (
              <TeacherDetails
                setSelectedTeacherId={setSelectedTeacherId}
                identity={identity}
                setLoading={setLoading}
                selectedTeacherId={selectedTeacherId}
                setToast={setToast}
                onCallbackOfEraseChannel={onCallbackOfEraseChannel}
              />
            ) : selectedStudentId ? (
              <StudentDetails
                setSelectedStudentId={setSelectedStudentId}
                selectedStudent={selectedStudent}
                setLoading={setLoading}
                setToast={setToast}
                onCallbackOfUserTypeChange={onCallbackOfUserTypeChange}
              />
            ) : (
              <TeacherList
                teacherList={teacherList}
                newTeacherList={newTeacherList}
                setTewTeacherList={setTewTeacherList}
                teacherEmails={teacherEmails}
                setTeacherList={setTeacherList}
                saveTeacherList={saveTeacherList}
                editable={!institutionId}
                setToast={setToast}
                details={details}
              />
            )}
          </div>
        </Grid>
        {identity.userType === "super" && (
          <Grid item lg={2} md={12} sm={12} className={classes.gridBoder}>
            <h2 className={classes.headerText}>Invite Admin</h2>
            <div style={{ marginBottom: "1rem" }}>
              <TextField
                variant="outlined"
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              className={classes.submitBtn}
              onClick={inviteAdmin}
              disabled={email.length === 0 ? "disabled" : ""}
            >
              Invite
            </button>

            <h3 className={classes.headerText} style={{ marginRight: "1rem" }}>
              Domain(s)
            </h3>
            <h4
              style={{
                overflow: "hidden",
                position: "relative",
                margin: "0 5px 0 5px",
                textDecoration: "none",
                textOverflow: "ellipsis",
              }}
            >
              {details.domain != ""
                ? JSON.parse(details.domain).join(", ")
                : ""}
            </h4>
            <ul className={classes.institutionList}>
              {authorizedDomains.map(
                (singleDomain) =>
                  identity.userId === singleDomain.invite_by &&
                  isStringified(singleDomain.domain).map(
                    (domainData, index) => (
                      <li
                        key={index}
                        style={{
                          overflow: "hidden",
                          position: "relative",
                          margin: "0 5px 0 5px",
                          textDecoration: "none",
                          textOverflow: "ellipsis",
                        }}
                      >
                        @{domainData}
                      </li>
                    )
                  )
              )}
            </ul>
            {/* )} */}
          </Grid>
        )}
      </Grid>
      <EditInstitutionDetailsModal
        open={editInstitutionModalOpen}
        setOpen={setEditInstitutionModalOpen}
        loading={loading}
        updateInstitutionDetails={updateInstitutionDetails}
        handleChangeInstitutionDetails={handleChangeInstitutionDetails}
        handleSubmit={editInstitutionDetails}
      />
      <DeleteInstitutionModal
        open={deleteInstitutionModalOpen}
        setOpen={setDeleteInstitutionModalOpen}
        loading={loading}
        handleClickDeleteInstitution={handleClickDeleteInstitution}
        details={details}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default Dashboard;
