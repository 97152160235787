import React from "react";
import { Link } from "react-router-dom";
import classes from "./Landing.module.css";

const Landing = () => {
  return (
    <div className={classes.container}>
      <p className={classes.heroText}>
        schooled <br /> educational
        <br /> content
      </p>
      <div>
        <Link
          className={`${classes.actionBtn} ${classes.loginBtn}`}
          to="/login"
        >
          Log In
        </Link>
      </div>
    </div>
  );
};

export default Landing;
