import React, { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { UIContext } from "../../contexts/UIContext";
import coverImage from "../../assets/images/cover_img.png";
import infoIcon from "../../assets/images/info.png";
import axiosInstance from "../../helpers/axiosInstance";
import classes from "./TeacherDetails.module.css";
import UpdateTeacherDetailsModal from "../Dialogs/UpdateTeacherDetailsModal";
import Toast from "../../components/ui/toast/Toast";
import CF from "../../utils/CommonFunction";
import defaultImage from "../../assets/images/defaultimage.png";
import { AuthContext } from "../../contexts/AuthContext";
import TeacherFollowListModal from "../Dialogs/TeacherFollowListModal";
import Linkify from "react-linkify";
import AddPlayListModal from "../Dialogs/AddPlayListModal";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";

const TeacherDetails = ({
  teacherId,
  setRatingInfo,
  setIsPlayListSubmited,
}) => {
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(UIContext);
  const [teacher, setTeacher] = useState({
    firstName: "",
    lastName: "",
    profileImage: "",
    bio: "",
  });
  const [editTeacherInfo, setEditTeacherInfo] = useState({});
  const [teacherData, setTeacherData] = useState({
    firstName: "",
    lastName: "",
    bio: "",
    subject: "",
    post_count: "",
    teacher_title: "",
    favorited_video_count: "",
  });
  const { id } = useParams();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [toast, setToast] = useState();
  const { identity } = useContext(AuthContext);
  const [follow, setFollowing] = useState("0");
  const [followerCount, setFollowerCount] = useState(0);
  const [teacherFollowListModalOpen, setTeacherFollowListModalOpen] =
    useState(false);
  const [userImage, setUserImage] = useState(defaultImage);
  const [teacherFollowerDetails, setTeacherFollowerDetails] = useState([]);
  const [newPlaylistModalOpen, setNewPlaylistModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [playListDetails, setPlayListDetails] = useState([]);
  const [postViews, setPostViews] = useState(0);

  useEffect(() => {
    if (newPlaylistModalOpen) {
      getPlayListDetails(identity.userId);
    }
  }, [newPlaylistModalOpen]);

  useEffect(() => {
    setTitle("");
  }, [newPlaylistModalOpen]);

  useEffect(() => {
    getTeacherDetails(id);
  }, []);

  useEffect(() => {
    getTeacherDetails(id);
  }, [id]);

  useEffect(() => {
    if (teacherFollowListModalOpen) {
      getTeacherFollowDetails();
    }
  }, [teacherFollowListModalOpen]);

  const getTeacherFollowDetails = () => {
    setLoading(true);
    axiosInstance
      .get("/student/follow-teacher")
      .then((res) => {
        setTeacherFollowerDetails(res.data.teachers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTeacherDetails = (teacherId) => {
    // setLoading(true);
    axiosInstance
      .get("/teacher/details?teacher_id=" + teacherId)
      .then((res) => {
        setTeacher(res.data.teacher);
        const {
          firstName,
          lastName,
          subject,
          bio,
          followers,
          followers_count,
          profileImage,
          overall_post_rating,
          average_post_rating,
          teacher_title,
          post_views,
        } = res.data.teacher;
        setPostViews(post_views);

        if (profileImage != "") {
          setUserImage(profileImage);
        }

        setRatingInfo({
          overall_post_rating,
          average_post_rating,
        });

        setFollowerCount(followers_count);
        setTeacherData({
          firstName,
          lastName,
          subject: subject || "",
          bio: bio || "",
          teacher_title: teacher_title || "",
        });
        const filteredData = followers.filter((item) => {
          return identity.userId === item.user_id;
        });
        if (filteredData.length > 0) {
          setFollowing("1");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const changeProfileImage = (e) => {
    const imageFile = e.target.files[0];
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    if (!imageFile.name.match(/\.(png|jpg|jpeg)$/)) {
      setToast(
        CF.alert.error(
          "The profile photo must be an image with type png, jpg or jpeg."
        )
      );
      return false;
    }
    setLoading(true);
    axiosInstance
      .put("/teacher/profile-image", formData)
      .then((res) => {
        setToast(CF.alert.success("Profile Image changed successfully"));
        getTeacherDetails(id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleTeacherDataChange = (e) => {
    setTeacherData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  //Validation for teacher profile update
  const validateUpdateProfileDetail = () => {
    let message = [];
    const nameRegex = /^[A-Za-z\s]+$/; // Regex to allow only alphabet characters and spaces

    //  Check if first name is empty
    if (teacherData.firstName === "") {
      message.push("Please enter first name.");
    }
    // Check if first name contains only letters
    else if (!nameRegex.test(teacherData.firstName)) {
      message.push(
        "First name can only contain letters. Numbers and special characters are not allowed."
      );
    }
    // Check if last name is empty
    if (teacherData.lastName === "") {
      message.push("Please enter last name.");
    } // Check if last name contains only letters
    else if (!nameRegex.test(teacherData.lastName)) {
      message.push(
        "Last name can only contain letters. Numbers and special characters are not allowed."
      );
    }
    // Check if subject is empty
    if (teacherData.subject === "") {
      message.push("Please enter subject.");
    }
    // Check if subject contains only letters
    else if (!nameRegex.test(teacherData.subject)) {
      message.push(
        "Subject can only contain letters. Numbers and special characters are not allowed."
      );
    }

    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  const updateTeacherDetails = (e) => {
    e.preventDefault();
    const isValid = validateUpdateProfileDetail();
    if (isValid) {
      setUpdateModalOpen(false);
      setLoading(true);
      axiosInstance
        .put("/teacher/detail", teacherData)
        .then((res) => {
          getTeacherDetails(id);
          setLoading(false);
          setToast(CF.alert.success("Profile details updated successfully"));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  /* Follow */
  const handleClickFollow = (e) => {
    let apiEndPoint = null;
    if (follow == "0") {
      apiEndPoint = "/teacher/follow";
    } else if (follow == "1") {
      apiEndPoint = "/teacher/unfollow";
    }
    setLoading(true);
    axiosInstance
      .post(apiEndPoint, {
        type: identity.userType,
        follower_id: teacher.userId,
      })
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          /* State Update for follow & following */
          if (follow == "0") {
            setFollowing("1");
            setFollowerCount(followerCount + 1);
            getTeacherDetails(id);
          } else {
            setFollowing("0");
            setFollowerCount(followerCount - 1);
            getTeacherDetails(id);
          }
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Validation for create play list
  const validateCreatePlayList = () => {
    let message = [];
    if (title.trim() === "") {
      message.push("Please, enter title.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast(CF.alert.error(message[i]));
      }
    }
    return message.length === 0;
  };

  //Create PlayList
  const createPlayList = () => {
    const isValid = validateCreatePlayList();
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/playlist/save", { title })
        .then((res) => {
          if (res.data.state === true) {
            setToast(CF.alert.success(res.data.msg));
            setNewPlaylistModalOpen(false);
            setIsPlayListSubmited(true);
            setTimeout(() => setIsPlayListSubmited(false), 100);
          } else {
            setToast(CF.alert.error(res.data.msg));
          }
        })
        .catch((err) => {
          setToast(
            CF.alert.error("Something went wrong, while creating playlist.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  //Listing API For PlayList
  const getPlayListDetails = (teacherId) => {
    axiosInstance
      .get("/playlist/lists?teacher_id=" + teacherId)
      .then((res) => {
        setPlayListDetails(res.data.playlists);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formatBio = (bio) => {
    return bio?.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/(.{10})/g, "$1 ");
  };

  const onEditTeacher = (teacherObject) => {
    setTeacherData((prev) => ({
      ...prev,
      firstName: teacherObject.firstName,
      lastName: teacherObject.lastName,
      subject: teacherObject.subject,
      bio: teacherObject.bio,
      teacher_title: teacherObject.teacher_title,
    }));
    setEditTeacherInfo(teacherObject);
    setUpdateModalOpen(true);
  };

  return (
    <div>
      <img src={coverImage} className={classes.coverImage} />
      <div className={classes.details}>
        <div className={classes.top}>
          <div>
            <img
              src={
                teacher?.profileImage?.includes("=")
                  ? teacher.profileImage?.split("=")[0]
                  : teacher?.profileImage || defaultImage
              }
              referrerpolicy="no-referrer"
              className={classes.profileImage}
              alt=""
            />
            {identity.userId === parseInt(teacherId) && (
              <>
                <input
                  type="file"
                  id="profileImageInput"
                  accept="image/*"
                  onChange={changeProfileImage}
                  hidden
                />
                <label
                  htmlFor="profileImageInput"
                  className={classes.uploadProfileBtn}
                >
                  &#43;
                </label>
              </>
            )}
          </div>
          <div className={classes.teachSubjectDetails}>
            <h4>Teaches {teacher.subject}</h4>
            {identity.userId !== parseInt(teacherId) && (
              <>
                <Button
                  className={classes.followBtn}
                  style={{
                    backgroundColor: follow === "0" ? "#fcd925" : "#87CA87",
                  }}
                  onClick={(e) => handleClickFollow(e)}
                >
                  {follow == "0" ? "Follow +" : "Following"}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={classes.mainDetails}>
          <div className={classes.metaDetails}>
            <div className={classes.nameText}>
              <h2>
                {teacher?.teacher_title ? teacher?.teacher_title : "Prof"}.{" "}
                {CF.capitalizeFirstLetter(teacher.firstName)}
                &nbsp;
                {CF.capitalizeFirstLetter(teacher.lastName)}
              </h2>
            </div>
            <p>
              {teacher?.institution?.name}
              <br />
              Teaches {teacher.subject}
              <br />
            </p>
          </div>
          <div className={classes.followingDetails}>
            <div className={classes.followingDetailsItem}>
              <h3>Following</h3>
              <label
                style={{
                  cursor:
                    identity.userId === parseInt(teacherId)
                      ? "pointer"
                      : "initial",
                }}
                onClick={() =>
                  identity.userId === parseInt(teacherId) &&
                  setTeacherFollowListModalOpen(true)
                }
              >
                {teacher.following_count ? teacher.following_count : 0}
              </label>
            </div>
            <div
              className={classes.followingDetailsItem}
              style={{ borderLeft: "1px solid grey" }}
            >
              <h3>Followers</h3>
              <label>
                {teacher.followers_count ? teacher.followers_count : 0}
              </label>
            </div>
          </div>
        </div>
        {identity.userId === parseInt(teacherId) && (
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <Button
              variant="contained"
              startIcon={<EditOutlinedIcon fontSize="medium" />}
              style={{
                backgroundColor: "var(--primary-color)",
                borderRadius: "12px",
                height: "40px",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                lineHeight: "normal",
              }}
              className={classes.editBtn}
              onClick={() => onEditTeacher(teacher)}
            >
              Edit Details
            </Button>
          </div>
        )}
        <div className={classes.aboutText}>
          <h4>About Me</h4>
          {teacher?.bio ? (
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => {
                return (
                  <a
                    key={key}
                    href={decoratedHref}
                    alt="Link"
                    rel="noreferrer"
                    target={"_blank"}
                    className={classes.links}
                  >
                    {decoratedText}
                  </a>
                );
              }}
            >
              {formatBio(teacher?.bio)}
            </Linkify>
          ) : (
            <div
              style={{
                fontWeight: "500",
                whiteSpace: "nowrap",
                opacity: "0.3",
                marginTop: "5px",
                padding: "11px 0px 11px",
              }}
            >
              <p style={{ fontSize: "20px" }}>No information available</p>
            </div>
          )}
        </div>
        {identity.userId === parseInt(teacherId) && (
          <>
            <span style={{ cursor: "not-allowed" }}>
              <Button
                variant="contained"
                onClick={() => navigate("/create-post")}
                startIcon={
                  <AddOutlinedIcon
                    style={{
                      fontSize: "20px",
                      verticalAlign: "middle",
                    }}
                  />
                }
                style={{
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "12px",
                  height: "40px",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBlock: "1rem",
                }}
                className={classes.createBtn}
                disabled={teacher.is_restrict_posting === "1"}
              >
                <span style={{ lineHeight: "20px" }}>Create Post</span>
              </Button>

              {teacher.is_restrict_posting == "1" && (
                <img
                  src={infoIcon}
                  title={
                    teacher?.is_restrict_posting == "1"
                      ? "You are restricted to create a post."
                      : ""
                  }
                  style={{
                    width: "25px",
                    marginLeft: "15px",
                    marginBottom: "-6px ",
                  }}
                />
              )}
            </span>
            <div>
              <Button
                variant="contained"
                onClick={() => setNewPlaylistModalOpen(true)}
                startIcon={
                  <PlaylistAddOutlinedIcon
                    style={{
                      fontSize: "22px",
                      verticalAlign: "middle",
                    }}
                  />
                }
                style={{
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "12px",
                  height: "40px",
                  color: "#fff",
                  marginBlock: "1.75rem",
                  display: "flex",
                  alignItems: "center",
                  padding: "0 12px",
                }}
                className={classes.createBtn}
                disabled={teacher.is_restrict_posting === "1"}
              >
                <span style={{ lineHeight: "20px", verticalAlign: "middle" }}>
                  Create Playlist
                </span>
              </Button>
            </div>
            <div className={classes.postDetail}>
              <b>Cumulative Views</b> : <span>{postViews}</span>
            </div>
            <div className={classes.postDetail}>
              <b>Total Posts</b> : <span>{teacher?.post_count}</span>
            </div>
            <div className={classes.postDetail}>
              <b>Favorited Videos</b> :
              <span>{teacher?.favorited_video_count}</span>
            </div>
          </>
        )}
      </div>
      <AddPlayListModal
        open={newPlaylistModalOpen}
        setOpen={setNewPlaylistModalOpen}
        createPlayList={createPlayList}
        title={title}
        setTitle={setTitle}
        loading={loading}
        playListDetails={playListDetails}
      />
      <UpdateTeacherDetailsModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        teacherData={teacherData}
        handleTeacherDataChange={handleTeacherDataChange}
        handleSubmit={updateTeacherDetails}
        editTeacherInfo={editTeacherInfo}
      />
      <TeacherFollowListModal
        open={teacherFollowListModalOpen}
        setOpen={setTeacherFollowListModalOpen}
        teacherFollowerDetails={teacherFollowerDetails}
      />
      {toast && <Toast data={toast} hideToast={() => setToast(null)} />}
    </div>
  );
};

export default TeacherDetails;
