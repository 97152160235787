function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function capitalizeFirstLetter(string) {
  if (string === "" || string === undefined) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function getRandomUniqueId(prefix) {
  prefix = prefix === undefined || prefix === "" ? "" : prefix;
  return prefix + Math.floor(Math.random() * 10000000000 + 1);
}

const alert = (function () {
  var alertTypes = {
    success: {
      status: "success",
      title: "Success",
    },
    error: {
      status: "error",
      title: "Error",
    },
    warning: {
      status: "warning",
      title: "Warning",
    },
    info: {
      status: "info",
      title: "Info",
    },
  };
  function getAlert(alertType, message) {
    return {
      status: alertType,
      title: alertTypes[alertType].title,
      body: message,
    };
  }
  return {
    success: function (message) {
      return getAlert("success", message);
    },
    error: function (message) {
      return getAlert("error", message);
    },
    warning: function (message) {
      return getAlert("warning", message);
    },
    info: function (message) {
      return getAlert("info", message);
    },
  };
})();

function assignRandomColorCodeInArray(arrOfObj) {
  const ITEM_COLORS = ["#87CA87", "#1784E9", "#353535", "#646464", "#F76843"];
  const getRandomColor = () =>
    ITEM_COLORS[Math.floor(Math.random() * ITEM_COLORS.length)];

  var arrOfObj = arrOfObj.map(function (el) {
    var o = Object.assign({}, el);
    o.color = getRandomColor();
    return o;
  });
  return arrOfObj;
}

function isValidEmailDomain(domainOfEmail) {
  const regex = /^(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
  return regex.test(domainOfEmail);
}

const lastPageAccess = (function () {
  const localStorageKeyName = "lastVisitedPage";
  const that = this;
  return {
    get: function () {
      let storedAccessInfo = localStorage.getItem(localStorageKeyName);
      if (storedAccessInfo) {
        storedAccessInfo = JSON.parse(storedAccessInfo);
      }
      return storedAccessInfo;
    },
    set: function (objectBasedOInType) {
      //manipulate
      let finalStorageInfo = {
        ...this.get(),
        ...objectBasedOInType,
      };
      // Put the object into storage
      localStorage.setItem(
        localStorageKeyName,
        JSON.stringify(finalStorageInfo)
      );
    },
    delete : {
       single : function(accessType) {
          let allAccessInfo = lastPageAccess.get();
          if(allAccessInfo.hasOwnProperty(accessType)) {
            delete allAccessInfo[accessType];
             // Put the object into storage
            localStorage.setItem(
              localStorageKeyName,
              JSON.stringify(allAccessInfo)
            );
            return true;
         }
         return false;
       }
    }
  };
})();

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default {
  isValidEmail,
  capitalizeFirstLetter,
  alert,
  assignRandomColorCodeInArray,
  isValidEmailDomain,
  getRandomUniqueId,
  lastPageAccess,
  isObjectEmpty
};
