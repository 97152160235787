import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../helpers/axiosInstance";
import { UIContext } from "../contexts/UIContext";
import CF from "../utils/CommonFunction";
import { AuthContext } from "../contexts/AuthContext";
import LectureDetails from "../components/lecture-details/LectureDetails";

/**
 * @author Nikita Mahajan
 * Date: 19 september 2022
 * @param {string} title - Feed video playing details
 * comment and rating add in video
 */

const LectureDetailsContainer = ({
  setLectureDetailsOpen,
  selectedFeedId,
  getFeed,
  lectures,
  onCallbackOfDeleteFeedPost,
  onActionCallBack,
}) => {
  const [viewRatingsModalOpen, setViewRatingsModalOpen] = useState(false);
  const [feedDetail, setFeedDetail] = useState({});
  const navigate = useNavigate();
  const { setLoading } = useContext(UIContext);
  const [ratingValue, setReatingValue] = useState(0);
  const [toast, setToast] = useState();
  const [comment, setComment] = useState("");
  const [commetnList, setcommetnList] = useState([]);
  const { identity } = useContext(AuthContext);
  const [showVideo, setShowVideo] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const location = useLocation();
  const { slug, id } = useParams();
  const [feedMoreDetail, setFeedMoreDetail] = useState([]);
  const [loadingYourFeed, setLoadingLoadingYourFeed] = useState(false);

  //Handle Change event for rating
  const handleChange = (e) => {
    setLoading(true);
    const currentRating = ratingValue;
    const selectedRating = parseInt(e.target.value);
    setReatingValue(selectedRating);

    let requestData = {
      post_id: selectedFeedId,
      post_user_id: feedDetail.teacher_id,
      type: identity.userType,
      rating: selectedRating,
    };
    //api call
    axiosInstance
      .post("/post/rating", requestData)
      .then((res) => {
        if (res.data.state === true) {
          setToast(CF.alert.success(res.data.msg));
          getFeedDetail(selectedFeedId, true, slug);
        } else {
          setToast(CF.alert.error(res.data.msg));
        }
      })
      .catch((err) => {
        console.error(err);
        setToast(
          CF.alert.error("Something went wrong, while givining a rating")
        );
        setReatingValue(currentRating);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedFeedId > 0) {
      getFeedDetail(selectedFeedId, true, slug);
    }
  }, [selectedFeedId]);

  useEffect(() => {
    getFeedMoreDetail(feedDetail?.teacher_id);
  }, [feedDetail]);

  const handleClick = (feedId, teacherId, slug) => {
    if (setLectureDetailsOpen === undefined) return;
    let requestData = {
      post_id: feedId,
      post_user_id: teacherId,
      user_type: identity.userType,
    };

    if (location.pathname.includes("/dashboard")) {
      navigate(`/dashboard/${slug}`);
    } else if (
      location.pathname.includes(`/teacher-profile/${teacherId}/lectures`)
    ) {
      navigate(`/teacher-profile/${teacherId}/lectures/${slug}`);
    } else if (location.pathname.includes(`/student-profile/lectures`)) {
      navigate(`/student-profile/lectures/${slug}`);
    } else if (
      location.pathname.includes(`/teacher-profile/${teacherId}/playlist`)
    ) {
      navigate(`/teacher-profile/${teacherId}/playlist/${slug}`);
    } else if (location.pathname.includes(`/student-profile/playlist`)) {
      navigate(`/student-profile/playlist/${slug}`);
    }

    axiosInstance
      .post("/post/view", requestData)
      .then((res) => {
        setLoading(false);
        setLectureDetailsOpen(feedId);
        getFeedMoreDetail(feedDetail?.teacher_id);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFeedDetail = (feedId, getComment = false, slug) => {
    setLoading(true);
    axiosInstance
      .get("post/detail?feedId=" + feedId + "&slug=" + slug)
      .then((res) => {
        let feedData = res.data.feed;

        setShowVideo(feedData.is_thumbnail == 0);
        setFeedDetail(feedData);
        setReatingValue(feedData?.user_rating?.rating || 0);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFeedMoreDetail = (teacherId) => {
    setLoading(true);
    setLoadingLoadingYourFeed(true);
    axiosInstance
      .get("post/feed?teacherId=" + teacherId)
      .then((res) => {
        let feedMoreData = res.data.feed;
        setFeedMoreDetail(feedMoreData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoadingLoadingYourFeed(false);
      });
  };

  //Validation for Student profile update
  const validatePostComment = () => {
    let message = [];
    if (comment === "") {
      message.push("Please enter comment.");
    }
    if (message.length > 0) {
      //looping of errors
      for (let i = 0; i < 1; i++) {
        setToast({
          status: "error",
          title: "Error",
          body: message[i],
        });
      }
    }
    return message.length === 0;
  };

  const handleClickKeyDownAddComment = (e) => {
    if (e.key === "Enter" && comment !== "") {
      let requestData = {
        post_id: selectedFeedId,
        parent_comment_id: null,
        comment,
      };
      axiosInstance
        .post("/post/comment", requestData)
        .then((res) => {
          setLoading(false);
          setToast(CF.alert.success(res.data.msg));
          let newComment = [res.data.postComment];
          setcommetnList(commetnList?.concat(newComment));
          setComment("");
          getFeedDetail(selectedFeedId, true, slug);
          // getCommentListing(selectedFeedId);
        })
        .catch((err) => {
          console.error(err);
          setToast(
            CF.alert.error("Something went wrong, while creating post.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
      setComment("");
    }
  };

  const handleClickAddComment = (e) => {
    let requestData = {
      post_id: selectedFeedId,
      parent_comment_id: null,
      comment,
    };
    const isValid = validatePostComment();
    if (isValid) {
      axiosInstance
        .post("/post/comment", requestData)
        .then((res) => {
          setLoading(false);
          setToast(CF.alert.success(res.data.msg));
          let newComment = [res.data.postComment];
          setcommetnList(commetnList?.concat(newComment));
          setComment("");
          getFeedDetail(selectedFeedId, true, slug);
        })
        .catch((err) => {
          console.error(err);
          setToast(
            CF.alert.error("Something went wrong, while creating post.")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  function manipulateTeacherStudentInfo(item) {
    let teacherInfo = {
      ...item.teacher,
      userType: "techer",
    };
    let studentInfo = {
      ...item.student,
      userType: "student",
    };
    return {
      ...teacherInfo,
      ...studentInfo,
    };
  }

  const onClickOfThumabilImage = () => {
    setShowVideo(true);
    setPlayVideo(true);
  };

  const handleClickCloseIcon = () => {
    setLectureDetailsOpen(0);
    if (location.pathname.includes("/dashboard")) {
      navigate("/dashboard");
    } else if (location.pathname.includes(`/student-profile/lectures`)) {
      navigate(`/student-profile/lectures`);
    } else if (location.pathname.includes(`/student-profile/playlist`)) {
      navigate(`/student-profile/playlist`);
    } else if (
      location.pathname.includes(
        `/teacher-profile/${feedDetail.teacher_id}/lectures`
      )
    ) {
      navigate(`/teacher-profile/${feedDetail.teacher_id}/lectures`);
    } else if (
      location.pathname.includes(
        `/teacher-profile/${feedDetail.teacher_id}/playlist`
      )
    ) {
      navigate(`/teacher-profile/${feedDetail.teacher_id}/playlist`);
    }
  };

  return (
    <div>
      <LectureDetails
        getFeedMoreDetail={getFeedMoreDetail}
        onActionCallBack={onActionCallBack}
        showVideo={showVideo}
        handleChange={handleChange}
        handleClickCloseIcon={handleClickCloseIcon}
        onClickOfThumabilImage={onClickOfThumabilImage}
        handleClickKeyDownAddComment={handleClickKeyDownAddComment}
        handleClickAddComment={handleClickAddComment}
        manipulateTeacherStudentInfo={manipulateTeacherStudentInfo}
        feedDetail={feedDetail}
        navigate={navigate}
        setLoading={setLoading}
        ratingValue={ratingValue}
        comment={comment}
        setComment={setComment}
        toast={toast}
        setToast={setToast}
        commetnList={commetnList}
        playVideo={playVideo}
        identity={identity}
        viewRatingsModalOpen={viewRatingsModalOpen}
        setViewRatingsModalOpen={setViewRatingsModalOpen}
        getFeed={getFeed}
        lectures={lectures}
        feedMoreDetail={feedMoreDetail}
        selectedFeedId={selectedFeedId}
        isLoading={loadingYourFeed}
        onCallbackOfDeleteFeedPost={onCallbackOfDeleteFeedPost}
        handleClick={handleClick}
      />
    </div>
  );
};

export default LectureDetailsContainer;
