import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import CF from "../../utils/CommonFunction";

const customStyles = {
  nameText: {
    marginLeft: "2rem",
    marginTop: "1rem",
  },
};

export default function TeacherFollowListModal({
  open,
  setOpen,
  teacherFollowerDetails,
}) {
  const navigate = useNavigate();

  const handleClickRedirectTeacherProfile = (userId) => {
    navigate("/teacher-profile/" + userId + "/lectures");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            <h2 style={{ fontSize: "20px" }}>Teacher Following List</h2>
          </DialogTitle>
          <CloseIcon
            onClick={() => setOpen(false)}
            fontSize="large"
            style={{
              marginTop: "1rem",
              marginRight: "1rem",
              cursor: "pointer",
            }}
          />
        </div>
        {teacherFollowerDetails?.length === 0 && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                padding: "1rem",
                textAlign: "center",
                fontSize: "2rem",
                opacity: "0.3"
              }}
            >
              No followers available
            </div>
          </div>
        )}
        <DialogContent>
          {teacherFollowerDetails.map((item) => (
            <div style={{ display: "flex" }} key={item}>
              <img
                referrerpolicy="no-referrer"
                src={item.profileImage}
                loading="lazy"
                style={{
                  height: "4rem",
                  width: "4rem",
                  marginBottom: "1rem",
                  borderRadius: "50%",
                  cursor: "pointer",
                  objectFit: "cover",
                  border: "4px solid #424242",
                }}
                onClick={() => handleClickRedirectTeacherProfile(item.userId)}
              />
              <h3 style={customStyles.nameText}>
                {item.teacher_title ? item.teacher_title : "Prof"}.{" "}
                {CF.capitalizeFirstLetter(item.firstName)}
                &nbsp;
                {item.lastName}
              </h3>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
